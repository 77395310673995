// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UQ3gbdWuteTHDeCKiCSX{background-color:var(--figma-color-bg);border-radius:2px;box-shadow:0px 2px 14px 0px rgba(0,0,0,.15)}.mX0wxOaOMnwJ26B1m5ei{margin:0;padding:4px 4px 2px 16px;border-bottom:1px solid var(--aninix-color-foreground-black-1)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/properties-panel-popper/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,sCCDa,CDEb,iBAAA,CACA,2CAAA,CAGF,sBACE,QAAA,CACA,wBAAA,CACA,8DAAA","sourceRoot":""}]);
// Exports
export var container = `UQ3gbdWuteTHDeCKiCSX`;
export var header = `mX0wxOaOMnwJ26B1m5ei`;
export default ___CSS_LOADER_EXPORT___;
