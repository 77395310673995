import {
  EntityType,
  EntityTypeComponent,
  HashComponent,
  PaintType,
  PaintTypeComponent,
  getEntryOrThrow,
  getPosition,
  getSize,
} from '@aninix-inc/model'
import { useAnalytics } from '@aninix/analytics'
import {
  Tool,
  useImagesStore,
  useProject,
  useTools,
  useViewport,
} from '@aninix/core'
import { CommentsPanel } from '@aninix/core/modules/comments-panel'
import { CommentsWrapper } from '@aninix/core/modules/comments-wrapper'
import { useLogger } from '@aninix/logger'
import { FeelingsBanner } from 'apps/web-app/src/modules/feelings-banner'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Scrollable } from '../../components/scrollable'
import { ProContextProvider } from '../../models/pro'
import { Helper } from '../helper'
import { PropertiesPanel } from '../properties-panel'
import { SelectionProvider } from '../selection'
import { Timeline } from '../timeline'
import { Toolbar } from '../toolbar'
import { Viewport } from '../viewport'
import * as styles from './index.scss'

/**
 * @todo refactor editor to use only react contexts and mobx stores.
 * It would simplify the overall flow of the data.
 */
export interface IProps {
  dashboardUrl: string
  /**
   * Determine if current user is a pro or not.
   */
  isPro: boolean
  /**
   * Fired every time when user clicks on the pro feature
   */
  beforeProFeatureAttepmtToAccess: () => void
  toolbarModuleCenterComponents?: React.ReactNode
  toolbarModuleRightComponents?: React.ReactNode
}
export const ProjectEditor: React.FCC<IProps> = observer(
  ({
    dashboardUrl,
    isPro,
    beforeProFeatureAttepmtToAccess,
    toolbarModuleCenterComponents,
    toolbarModuleRightComponents,
  }) => {
    const project = useProject()
    const imagesStore = useImagesStore()
    const viewport = useViewport()
    const analytics = useAnalytics()
    const tools = useTools()
    const logger = useLogger()

    /**
     * @description update stores when project updated
     */
    React.useEffect(() => {
      // @NOTE: trigger store to load all images
      const imageHashes = project
        .getEntitiesByPredicate(
          (entity) =>
            entity.getComponentOrThrow(EntityTypeComponent).value ===
              EntityType.Paint &&
            entity.getComponentOrThrow(PaintTypeComponent).value ===
              PaintType.Image
        )
        .map((paint) => paint.getComponentOrThrow(HashComponent).value)
      imagesStore
        .clean()
        .loadImagesByHashes(imageHashes)
        .then(() => {
          logger.log('[images] loaded')
        })

      analytics.setProjectData({ id: project.id })
    }, [])

    React.useEffect(() => {
      const subscription = reaction(
        () => viewport.sizeVersion,
        () => {
          // @NOTE: required to properly set zoom in viewport when project loaded for the first time
          const entry = getEntryOrThrow(project)
          const position = getPosition(entry)
          const size = getSize(entry)
          viewport.zoomToRect({
            x: position.x,
            y: position.y,
            width: size.x,
            height: size.y,
          })
        }
      )

      return subscription
    }, [])

    return (
      <ProContextProvider
        isPro={isPro}
        beforeProFeatureAttepmtToAccess={beforeProFeatureAttepmtToAccess}
      >
        <SelectionProvider project={project}>
          <div className={styles.container}>
            <Toolbar
              displayShareProject
              dashboardUrl={dashboardUrl}
              project={project}
              centerComponents={toolbarModuleCenterComponents}
              rightComponents={toolbarModuleRightComponents}
            />

            <div className={styles.layout}>
              <div className={styles['working-area-wrapper']}>
                <div className={styles['viewport-wrapper']}>
                  <CommentsWrapper
                    isShowingComments={tools.activeTool === Tool.Comments}
                  >
                    <Viewport project={project} />
                  </CommentsWrapper>
                </div>

                <div className="relative">
                  <div className="absolute bottom-full pb-3 pl-2">
                    <FeelingsBanner />
                  </div>
                  <Timeline />
                </div>
              </div>

              <Scrollable>
                <div className={styles['properties-panel-wrapper']}>
                  <>
                    {tools.activeTool === Tool.Comments ? (
                      <CommentsPanel />
                    ) : (
                      <PropertiesPanel />
                    )}
                  </>
                </div>
              </Scrollable>

              <Helper />
            </div>
          </div>
        </SelectionProvider>
      </ProContextProvider>
    )
  }
)

ProjectEditor.displayName = 'ProjectEditor'
