// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KjmQBCfF0FVQQLLSwPAj{position:absolute;top:1px;height:calc(100% - 2px)}.qUruvfDGQWQ8AmRWsYbz{position:absolute;top:0;left:0;z-index:1;width:100%;height:100%;margin:0;padding:0;background:none;border:none;border-radius:8px;cursor:default}.qUruvfDGQWQ8AmRWsYbz:hover{opacity:.85}.qUruvfDGQWQ8AmRWsYbz:focus{outline:none}.C5ZoxecntlMzlTsXzuAB{position:absolute;top:50%;left:0;z-index:2;height:100%;margin:0;padding:0;background:none;border:none;transform:translateY(-50%);cursor:ew-resize;aspect-ratio:1/1}.C5ZoxecntlMzlTsXzuAB:focus{outline:none}.Vf3B98Xtfhzy9iRJcfgL{right:0;left:auto}.C5ZoxecntlMzlTsXzuAB span{position:absolute;top:50%;left:50%;display:block;width:4px;height:4px;background-color:#fff;border-radius:50%;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/layer/group-of-segments/index.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,OAAA,CACA,uBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CAEA,4BACE,WAAA,CAGF,4BACE,YAAA,CAIJ,sBACE,iBAAA,CACA,OAAA,CACA,MAAA,CACA,SAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,0BAAA,CACA,gBAAA,CACA,gBAAA,CAEA,4BACE,YAAA,CAGF,sBACE,OAAA,CACA,SAAA,CAGF,2BACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,aAAA,CACA,SAAA,CACA,UAAA,CACA,qBAAA,CACA,iBAAA,CACA,+BAAA","sourceRoot":""}]);
// Exports
export var container = `KjmQBCfF0FVQQLLSwPAj`;
export var segment = `qUruvfDGQWQ8AmRWsYbz`;
export var button = `C5ZoxecntlMzlTsXzuAB`;
export var button__right = `Vf3B98Xtfhzy9iRJcfgL`;
export default ___CSS_LOADER_EXPORT___;
