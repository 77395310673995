// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.isZ4ORlldfjpwbb0lgrV{height:100% !important}.g7p3kKo38e6v7V5tIwRr{--text-color: \$accent-blue;display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:flex-start;font-weight:400;font-size:10px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}._NhK_euCSsjpiBzvyYKH{display:flex;flex-direction:column;flex-wrap:nowrap;align-items:center;justify-content:center;height:16px;padding:4px 0}._7wdTMWiNrpah1JrJjC3{width:100%;max-width:48px}._7wdTMWiNrpah1JrJjC3 input{padding:3px 4px;text-align:center}.IbL2sfCR1bK65YnhKaTK{margin:0;color:var(--text-color);font-variant-numeric:tabular-nums}.VYsCcmQqb0r1zCQCyrdj{margin:0;color:var(--figma-color-text-disabled)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/info/property-values/numeric-value/styles.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,sBAAA,CAGF,sBACE,0BAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,0BAAA,CCTA,eAAA,CACA,cAAA,CACA,8BCLa,CDMb,gBAAA,CACA,qBAAA,CDUF,sBACE,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,aAAA,CAEF,sBACE,UAAA,CACA,cAAA,CAEA,4BACE,eAAA,CACA,iBAAA,CAIJ,sBACE,QAAA,CACA,uBAAA,CACA,iCAAA,CAGF,sBACE,QAAA,CACA,sCGuGuB","sourceRoot":""}]);
// Exports
var _1 = `isZ4ORlldfjpwbb0lgrV`;
export { _1 as "full-height" };
export var container = `g7p3kKo38e6v7V5tIwRr`;
var _2 = `_NhK_euCSsjpiBzvyYKH`;
export { _2 as "input-container" };
export var input = `_7wdTMWiNrpah1JrJjC3`;
export var text = `IbL2sfCR1bK65YnhKaTK`;
export var comment = `VYsCcmQqb0r1zCQCyrdj`;
export default ___CSS_LOADER_EXPORT___;
