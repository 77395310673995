// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cy9exOm4X9jbs71owJXw{position:relative;width:100%;height:20px;padding:0;color:var(--figma-color-text-disabled);background:none;border:none;border-bottom:1px solid var(--aninix-color-foreground-black-05);outline:none;cursor:default}.Yz9o2lklGdLI_5I_3zh6{position:absolute;top:0;display:flex;flex-direction:column;align-items:center;justify-content:space-between;transform:translateX(-50%)}.Yz9o2lklGdLI_5I_3zh6:nth-of-type(1){align-items:flex-start;transform:translateX(0)}.Yz9o2lklGdLI_5I_3zh6:nth-last-of-type(-n + 1){align-items:flex-end;transform:translateX(-100%)}.AkCtE_jlkWnxKqtl4Wjy{font-weight:500;font-size:9px;font-family:"Inter",sans-serif;line-height:12px;letter-spacing:.005em}._8uOryPa3Cbgd5AonIQ0T{display:block;width:2px;height:4px;background-color:var(--aninix-color-foreground-black-05)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/time-indicators/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,sCC4IuB,CD3IvB,eAAA,CACA,WAAA,CACA,+DAAA,CACA,YAAA,CACA,cAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,6BAAA,CACA,0BAAA,CAEA,qCACE,sBAAA,CACA,uBAAA,CAGF,+CACE,oBAAA,CACA,2BAAA,CAGF,sBEQA,eAAA,CACA,aAAA,CACA,8BC5Ca,CD6Cb,gBAAA,CACA,qBAAA,CFRA,uBACE,aAAA,CACA,SAAA,CACA,UAAA,CACA,wDIvCmB","sourceRoot":""}]);
// Exports
export var container = `Cy9exOm4X9jbs71owJXw`;
export var item = `Yz9o2lklGdLI_5I_3zh6`;
var _1 = `AkCtE_jlkWnxKqtl4Wjy`;
export { _1 as "item-text" };
var _2 = `_8uOryPa3Cbgd5AonIQ0T`;
export { _2 as "item-separator" };
export default ___CSS_LOADER_EXPORT___;
