import * as React from 'react'

import {
  ChildrenRelationsAspect,
  CornerRadiusComponent,
  DurationComponent,
  Ellipse,
  FillsRelationsAspect,
  Frame,
  Project,
  Root,
  SolidPaint,
  StrokeWeightComponent,
  StrokesRelationsAspect,
  SystemsProvider,
  createComponentsProvider,
  createEntitiesProvider,
  getEntryOrThrow,
} from '@aninix-inc/model'
import { PresetPreview } from '../../components/preset-preview'
import { Preset } from '../../models/preset'
import * as styles from './view-preset.scss'

export interface IProps {
  preset: Preset
  onClick: () => void
}
export const ViewPreset: React.FCC<IProps> = ({ preset, onClick }) => {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const previewProjectRef = React.useRef<Project>(
    (() => {
      const project = new Project({
        componentsProvider: createComponentsProvider(),
        entitiesProvider: createEntitiesProvider(),
        systemsProvider: new SystemsProvider([]),
      })

      const root = project.createEntity(Root)
      root.updateComponent(DurationComponent, preset.duration)

      const ellipse = project
        .createEntity(Ellipse, {
          anchorPoint: { x: 8, y: 8 },
          position: {
            x: 16,
            y: 8,
            tx1: 16,
            ty1: 8,
            tx2: 16,
            ty2: 8,
          },
          size: { x: 16, y: 16 },
        })
        .updateComponent(CornerRadiusComponent, 2)
      ellipse.getAspectOrThrow(FillsRelationsAspect).clear()
      ellipse.getAspectOrThrow(StrokesRelationsAspect).clear()

      const entry = project.createEntity(Frame, {
        isEntry: true,
        size: { x: 48, y: 32 },
      })
      entry.getAspectOrThrow(ChildrenRelationsAspect).addChild(ellipse)
      entry.getAspectOrThrow(FillsRelationsAspect).clear()
      entry.getAspectOrThrow(StrokesRelationsAspect).clear()

      if (preset.id === 'trim-path') {
        ellipse.getAspectOrThrow(StrokesRelationsAspect).addChild(
          project.createEntity(SolidPaint, {
            r: 255,
            g: 255,
            b: 255,
            a: 0.6,
          })
        )
        ellipse.updateComponent(StrokeWeightComponent, 2)
      } else {
        ellipse.getAspectOrThrow(FillsRelationsAspect).addChild(
          project.createEntity(SolidPaint, {
            r: 255,
            g: 255,
            b: 255,
            a: 0.6,
          })
        )
      }

      preset.apply({
        nodes: entry
          .getAspectOrThrow(ChildrenRelationsAspect)
          .getChildrenList(),
        // @NOTE: for preview we show animation from start
        time: 0,
      })

      return project
    })()
  )

  const handleClick = React.useCallback(() => {
    onClick()
  }, [onClick])

  const play = React.useCallback(() => {
    setIsPlaying(true)
  }, [])

  const stop = React.useCallback(() => {
    setIsPlaying(false)
  }, [])

  const entry = getEntryOrThrow(previewProjectRef.current!)

  return (
    <button
      onClick={handleClick}
      className={styles.wrapper}
      onMouseEnter={play}
      onMouseLeave={stop}
    >
      <div className={styles.container}>
        <PresetPreview node={entry} preset={preset} isActive={isPlaying} />
      </div>
    </button>
  )
}
