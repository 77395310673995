// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.in8znBZSBAzs2MwUaSSk{position:relative;display:flex;flex-direction:column;flex-shrink:0;align-items:center;justify-content:center;padding:0;background:none;border:none;border-radius:0;outline:none;cursor:default;transition:unset}.in8znBZSBAzs2MwUaSSk ._Gz891jikC4hdOkJGAbe{position:absolute;top:0;left:0;width:100%;height:100%;border:2px solid rgba(0,0,0,0);border-radius:2px;pointer-events:none}.LMwfkOrP7hCZ2YYRlsA9:hover{background:#000 !important}.in8znBZSBAzs2MwUaSSk:hover{background:var(--aninix-color-foreground-black-1)}.in8znBZSBAzs2MwUaSSk:active ._Gz891jikC4hdOkJGAbe{border-color:var(--figma-color-bg-brand)}.in8znBZSBAzs2MwUaSSk:disabled{cursor:no-drop;opacity:.35}.in8znBZSBAzs2MwUaSSk:disabled:hover{background:none}.IqPc1B2L2tTNm3l769Xv{background:var(--aninix-color-foreground-black-1)}.XDgocfqwInRYIvAL_Hbo{background:var(--figma-color-bg-brand-tertiary)}.XDgocfqwInRYIvAL_Hbo:hover{background:var(--figma-color-bg-selected)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/buttons/icon-button/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CAEA,aAAA,CACA,kBAAA,CACA,sBAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CAEA,4CACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,8BAAA,CACA,iBAAA,CACA,mBAAA,CAIA,4BACE,0BAAA,CAIJ,4BACE,iDChCkB,CDoClB,mDACE,wCEtCc,CF0ClB,+BACE,cAAA,CACA,WAAA,CAEA,qCACE,eAAA,CAIJ,sBACE,iDCnDkB,CDsDpB,sBACE,+CErDwB,CFuDxB,4BACE,yCEpCiB","sourceRoot":""}]);
// Exports
export var container = `in8znBZSBAzs2MwUaSSk`;
var _1 = `_Gz891jikC4hdOkJGAbe`;
export { _1 as "container--with-border" };
var _2 = `LMwfkOrP7hCZ2YYRlsA9`;
export { _2 as "container--contrast" };
var _3 = `IqPc1B2L2tTNm3l769Xv`;
export { _3 as "container--active" };
var _4 = `XDgocfqwInRYIvAL_Hbo`;
export { _4 as "container--selected" };
export default ___CSS_LOADER_EXPORT___;
