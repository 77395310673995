// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tj9Y5VcAyCNX6kCj6cFg{position:relative}.T8lVelToEEUgK2HmM2bb{position:absolute;top:0;right:0;z-index:1001;width:2px;height:100%;transform:translateX(50%);cursor:ew-resize}.T8lVelToEEUgK2HmM2bb::after{position:absolute;top:0;left:50%;width:2px;height:100%;transform:translateX(-50%);content:""}.fZZVsG2UiKRCxO9lrZE6::after{background-color:var(--figma-color-bg-brand)}.D8OrMaVfQnIW4OoqSUFB{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:space-between;height:38px;padding:0 8px;background-color:var(--figma-color-bg);border-bottom:1px solid var(--aninix-color-foreground-black-05)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/info/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,YAAA,CACA,SAAA,CACA,WAAA,CACA,yBAAA,CACA,gBAAA,CAEA,6BACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,SAAA,CACA,WAAA,CACA,0BAAA,CACA,UAAA,CAIA,6BACE,4CCzBc,CD8BpB,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,6BAAA,CAEA,WAAA,CAEA,aAAA,CACA,sCCzCa,CD0Cb,+DAAA","sourceRoot":""}]);
// Exports
export var container = `Tj9Y5VcAyCNX6kCj6cFg`;
export var resizer = `T8lVelToEEUgK2HmM2bb`;
var _1 = `fZZVsG2UiKRCxO9lrZE6`;
export { _1 as "resizer--active" };
export var time_control_container = `D8OrMaVfQnIW4OoqSUFB`;
export default ___CSS_LOADER_EXPORT___;
