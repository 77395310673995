// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v8dN_cMjambllP1Y7LHi{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:flex-start;justify-content:flex-start;width:100%;padding:8px;background-color:var(--aninix-color-foreground-black-05);border-radius:8px}._9jxE9GLFmn5WBJxa1sY{margin-top:2px;margin-right:4px}.TyfcvEDVDJIeYWfYnb59{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}.LDmlw_96SxNspqmEaOGV{font-weight:500;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;display:block;margin:0;margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/pro-feature-description/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,sBAAA,CACA,0BAAA,CACA,UAAA,CACA,WAAA,CACA,wDCPqB,CDQrB,iBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CAGF,sBERE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CFQF,sBE2BE,eAAA,CACA,cAAA,CACA,8BCpDa,CDqDb,gBAAA,CACA,qBAAA,CF7BA,aAAA,CACA,QAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var container = `v8dN_cMjambllP1Y7LHi`;
export var badge = `_9jxE9GLFmn5WBJxa1sY`;
export var content = `TyfcvEDVDJIeYWfYnb59`;
export var title = `LDmlw_96SxNspqmEaOGV`;
export default ___CSS_LOADER_EXPORT___;
