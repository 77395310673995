import {
  AnchorPointComponent,
  Component,
  ConstructorWithTag,
  CurveType,
  EffectsRelationsAspect,
  Entity,
  FillsRelationsAspect,
  getAnimatableValue,
  getComponentValueType,
  OpacityComponent,
  RotationComponent,
  setAnimatableValue,
  setCurveType,
  SpringCurveComponent,
  StrokesRelationsAspect,
  TimingCurveComponent,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { TimingCurve } from '@aninix/figma'
import * as R from 'ramda'
import type { Transition, TransitionOptions } from './transition'

type Options = {
  timeStart: number
  timeEnd: number
  curve: TimingCurve
}

function animate<T>(
  component: Component<T>,
  from: T,
  to: T,
  options: Options
): void {
  const { timeStart, timeEnd, curve } = options

  const leftKeyframe = setAnimatableValue(component, from, timeStart, true)
  const rightKeyframe = setAnimatableValue(component, to, timeEnd)

  if (curve.type === CurveType.Spring) {
    setCurveType(leftKeyframe, curve.type)
    leftKeyframe.updateComponent(SpringCurveComponent, curve.value)
  }

  if (curve.type === CurveType.Timing) {
    leftKeyframe.updateComponent(TimingCurveComponent, curve.value)
    rightKeyframe?.updateComponent(TimingCurveComponent, curve.value)
  }
}

function blendComponents(
  left: Entity,
  right: Entity,
  options: TransitionOptions
): void {
  const { timeStart, timeEnd, includeComponents } = options

  // @NOTE: handle case when layers not visible in viewport
  let opacityAnimated = false
  if (
    left.hasComponent(VisibleInViewportComponent) &&
    right.hasComponent(VisibleInViewportComponent) &&
    left.hasComponent(OpacityComponent) &&
    right.hasComponent(OpacityComponent) &&
    left.getComponentOrThrow(VisibleInViewportComponent).value !==
      right.getComponentOrThrow(VisibleInViewportComponent).value
  ) {
    const startOpacity =
      left.getComponentOrThrow(VisibleInViewportComponent).value === false
        ? 0
        : getAnimatableValue(
            left.getComponentOrThrow(OpacityComponent),
            options.timeStart
          )
    const endOpacity =
      right.getComponentOrThrow(VisibleInViewportComponent).value === false
        ? 0
        : getAnimatableValue(
            right.getComponentOrThrow(OpacityComponent),
            options.timeEnd
          )

    if (R.not(R.equals(startOpacity, endOpacity))) {
      animate(
        left.getComponentOrThrow(OpacityComponent),
        startOpacity,
        endOpacity,
        options
      )
    }
    left.updateComponent(VisibleInViewportComponent, true)
    opacityAnimated = true
  }

  for (const leftComponent of left.components) {
    const isStatic = (() => {
      try {
        getComponentValueType(leftComponent)
        return false
      } catch {
        return true
      }
    })()

    if (isStatic) {
      continue
    }

    const ComponentConstructor =
      leftComponent.constructor as ConstructorWithTag<Component>

    if (
      includeComponents != null &&
      !includeComponents
        .map((Constructor) => Constructor.tag)
        .includes(ComponentConstructor.tag)
    ) {
      continue
    }

    const rightComponent = right.getComponentOrThrow(ComponentConstructor)
    const leftValue = getAnimatableValue(leftComponent, timeStart) as any
    const rightValue = getAnimatableValue(rightComponent, timeEnd) as any

    // @NOTE: ignore anchor point animations
    if (ComponentConstructor.tag === AnchorPointComponent.tag) {
      continue
    }

    // if (ComponentConstructor.tag === PositionComponent.tag) {
    //   const leftAbsoluteMatrix = getAbsoluteTransformMatrix({
    //     entity: left,
    //     time: options.timeEnd,
    //   })
    //   const rightAbsoluteMatrix = getAbsoluteTransformMatrix({
    //     entity: right,
    //     time: options.timeStart,
    //   })
    //   const deltaMatrix = multipliedMatrices(
    //     invertedMatrix(leftAbsoluteMatrix),
    //     rightAbsoluteMatrix
    //   )
    //   const { translation: delta } = decomposedMatrix(unflatten(deltaMatrix))

    // const value = {
    //   x: rightValue.x + delta.x,
    //   y: rightValue.y + delta.y,
    //   tx1: rightValue.tx1 + delta.x,
    //   ty1: rightValue.ty1 + delta.y,
    //   tx2: rightValue.tx2 + delta.x,
    //   ty2: rightValue.ty2 + delta.y,
    // }
    // if (R.not(R.equals(leftValue, value))) {
    //   animate(leftComponent, leftValue, value, options)
    // }
    //   continue
    // }

    // @NOTE: in case of right entity is hidden
    // @TODO: add proper handling of colors animation
    if (
      ComponentConstructor.tag === OpacityComponent.tag &&
      opacityAnimated === false
    ) {
      if (R.not(R.equals(leftValue, rightValue))) {
        animate(leftComponent, leftValue, rightValue, options)
      }
      continue
    }

    // @NOTE: we have different rotation logic from Figma:
    // Input:  0, 45, 90, 135, 180, -135, -90, -45, 0
    // Output: 0, 45, 90, 135, 180, 225, 270, 315, 360
    if (ComponentConstructor.tag === RotationComponent.tag) {
      const value = (() => {
        const leftSign = Math.sign(leftValue)
        const isSwitched = Math.abs(leftValue - rightValue) > 180

        if (isSwitched) {
          return 360 * leftSign + rightValue
        }

        return rightValue
      })()

      if (R.not(R.equals(leftValue, value))) {
        animate(leftComponent, leftValue, value, options)
      }

      continue
    }

    if (R.not(R.equals(leftValue, rightValue))) {
      animate(leftComponent, leftValue, rightValue, options)
    }
  }
}

/**
 * Applies `smart animate` to provided pair.
 */
export const smartAnimateTransition: Transition = (pair, options) => {
  // @NOTE: required to properly cast types
  if (pair.left == null || pair.right == null) {
    return
  }

  // @NOTE: create animation on the left entities
  blendComponents(pair.left.entity, pair.right.entity, options)

  const project = pair.left.entity.getProjectOrThrow()

  if (
    pair.left.entity.hasAspect(FillsRelationsAspect) &&
    pair.right.entity.hasAspect(FillsRelationsAspect)
  ) {
    const leftAspect = pair.left.entity.getAspectOrThrow(FillsRelationsAspect)
    const rightAspect = pair.right.entity.getAspectOrThrow(FillsRelationsAspect)
    const length = Math.max(
      leftAspect.getChildrenList().length,
      rightAspect.getChildrenList().length
    )
    for (let i = 0; i < length; i += 1) {
      const left = leftAspect.getChildAt(i)
      const right = rightAspect.getChildAt(i)

      // @NOTE: we are making sure above that we have equal number of fills/strokes/effects between both nodes.
      if (left == null && right != null) {
        leftAspect.addChild(
          project.createEntityFromSnapshot(right.getSnapshot())
        )
        continue
      }

      if (left != null && right == null) {
        continue
      }

      if (left != null && right != null) {
        blendComponents(left, right, options)
      }
    }
  }

  if (
    pair.left.entity.hasAspect(StrokesRelationsAspect) &&
    pair.right.entity.hasAspect(StrokesRelationsAspect)
  ) {
    const leftAspect = pair.left.entity.getAspectOrThrow(StrokesRelationsAspect)
    const rightAspect = pair.right.entity.getAspectOrThrow(
      StrokesRelationsAspect
    )
    const length = Math.max(
      leftAspect.getChildrenList().length,
      rightAspect.getChildrenList().length
    )
    for (let i = 0; i < length; i += 1) {
      const left = leftAspect.getChildAt(i)
      const right = rightAspect.getChildAt(i)

      // @NOTE: we are making sure above that we have equal number of fills/strokes/effects between both nodes.
      if (left == null && right != null) {
        leftAspect.addChild(
          project.createEntityFromSnapshot(right.getSnapshot())
        )
        continue
      }

      if (left != null && right == null) {
        continue
      }

      if (left != null && right != null) {
        blendComponents(left, right, options)
      }
    }
  }

  if (
    pair.left.entity.hasAspect(EffectsRelationsAspect) &&
    pair.right.entity.hasAspect(EffectsRelationsAspect)
  ) {
    const leftAspect = pair.left.entity.getAspectOrThrow(EffectsRelationsAspect)
    const rightAspect = pair.right.entity.getAspectOrThrow(
      EffectsRelationsAspect
    )
    const length = Math.max(
      leftAspect.getChildrenList().length,
      rightAspect.getChildrenList().length
    )
    for (let i = 0; i < length; i += 1) {
      const left = leftAspect.getChildAt(i)
      const right = rightAspect.getChildAt(i)

      if (left == null && right != null) {
        const Constructor =
          project.entitiesProvider.getEntityConstructorByTagName(
            // @ts-ignore
            right.constructor.tag
          )
        const newLeft = project.createEntity(Constructor)
        leftAspect.addChild(newLeft)
        blendComponents(newLeft, right, options)
        continue
      }

      if (left != null && right == null) {
        continue
      }

      if (left != null && right != null) {
        blendComponents(left, right, options)
      }
    }
  }
}
