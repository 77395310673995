// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cU0fSEXieQv21FeDhajW{position:relative}.xJK2cJpK8ABLhmR8Wc1Y{position:relative;width:100%;height:32px;padding:0 8px;color:var(--figma-color-text);background:none;border:1px solid rgba(0,0,0,0);border-radius:2px;outline:none;cursor:default}.xJK2cJpK8ABLhmR8Wc1Y::placeholder{color:var(--figma-color-text)}.xJK2cJpK8ABLhmR8Wc1Y:hover{border-color:var(--aninix-color-foreground-black-1) !important}.xJK2cJpK8ABLhmR8Wc1Y:focus{padding-right:7px;border-color:var(--figma-color-bg-brand) !important;border-width:2px}.TwERhOMY7WymBKuR2_q3{padding-left:28px}.TwERhOMY7WymBKuR2_q3:focus{padding-left:27px}.DGEFVJSgpWs6Tzh767hi{border-color:var(--aninix-color-foreground-black-05) !important}.r5dZfFotSY1zajI1pKrh{font-weight:400;font-size:10px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}.D4mRRnpmsLiVSDs0f3BC{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}.qaZlzOCJZJlK4PDCjDmm{position:absolute;top:50%;left:8px;transform:translateY(-50%)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/input/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,6BC6He,CD5Hf,eAAA,CACA,8BAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CAEA,mCACE,6BCqHa,CDlHf,4BACE,8DAAA,CAGF,4BAEE,iBAAA,CACA,mDAAA,CACA,gBAAA,CAIJ,sBACE,iBAAA,CAEA,4BAEE,iBAAA,CAIJ,sBACE,+DAAA,CAGF,sBE5CE,eAAA,CACA,cAAA,CACA,8BCLa,CDMb,gBAAA,CACA,qBAAA,CF4CF,sBExCE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CFwCF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
export var container = `cU0fSEXieQv21FeDhajW`;
export var input = `xJK2cJpK8ABLhmR8Wc1Y`;
var _1 = `TwERhOMY7WymBKuR2_q3`;
export { _1 as "with-icon" };
export var border = `DGEFVJSgpWs6Tzh767hi`;
export var small = `r5dZfFotSY1zajI1pKrh`;
export var medium = `D4mRRnpmsLiVSDs0f3BC`;
export var icon = `qaZlzOCJZJlK4PDCjDmm`;
export default ___CSS_LOADER_EXPORT___;
