import {
  ChildrenRelationsAspect,
  Entity,
  EntryComponent,
  getAnimatableValue,
  getSize,
  getSortedKeyframes,
  NameComponent,
  NumberValueComponent,
  OpacityComponent,
  setAnchorPoint,
  setAnimatableValue,
  setEndTime,
  setStartTime,
  TimeComponent,
  TimingCurveComponent,
} from '@aninix-inc/model'

export namespace PrototypeNode {
  export type Type = {
    entity: Entity

    /**
     * Add indicies to copied layer names.
     * @example
     * ```
     * - rectangle
     * - rectangle
     * - ellipse
     * ```
     * ->
     * ```
     * - rectangle 1
     * - rectangel 2
     * - ellipse
     * ```
     */
    renameChildrenWithIndicies: () => void

    appear: (options: { timeStart: number; timeEnd: number }) => void

    disappear: (options: { timeStart: number; timeEnd: number }) => void
  }

  export class Base implements Type {
    constructor(public readonly entity: Entity) {
      // @NOTE: anchor point at center temporarily disabled.
      // @TODO: enable
      return

      if (entity.hasComponent(EntryComponent) === false) {
        const initialSize = getSize(entity)
        setAnchorPoint(entity, {
          x: initialSize.x / 2,
          y: initialSize.y / 2,
        })
      }
    }

    renameChildrenWithIndicies: Type['renameChildrenWithIndicies'] = () => {
      let stack: Entity[] = [this.entity]

      while (stack.length > 0) {
        const entity = stack.pop()

        if (entity == null) {
          throw new Error('Invalid state')
        }

        if (entity.hasAspect(ChildrenRelationsAspect)) {
          const namesMap = new Map<string, number>()
          const children = entity
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
          for (const child of children) {
            const name = child.getComponentOrThrow(NameComponent).value
            const existingIndex = namesMap.get(name)

            if (existingIndex != null) {
              child.updateComponent(
                NameComponent,
                (name) => `${name} - ${existingIndex}`
              )
              namesMap.set(name, existingIndex + 1)
              continue
            }

            namesMap.set(name, 1)
          }

          stack.push(...children)
        }
      }
    }

    appear: Type['appear'] = ({ timeStart, timeEnd }) => {
      setStartTime(this.entity, timeStart)

      const keyframesInRange = getSortedKeyframes(
        this.entity.getComponentOrThrow(OpacityComponent)
      ).filter((keyframe) => {
        const time = keyframe.getComponentOrThrow(TimeComponent).value
        return time >= timeStart && time <= timeEnd
      })
      if (keyframesInRange.length > 0) {
        return
      }

      const opacity = this.entity.getComponentOrThrow(OpacityComponent)
      const startOpacity = 0
      const endOpacity = getAnimatableValue(opacity, timeEnd)

      let startKeyframe = keyframesInRange[0]
      let endKeyframe = keyframesInRange[keyframesInRange.length - 1]

      // @NOTE: stop processing if layer is already visible
      if (
        startKeyframe != null &&
        startKeyframe.getComponentOrThrow(NumberValueComponent).value ===
          endOpacity
      ) {
        return
      }

      if (startKeyframe == null) {
        startKeyframe = setAnimatableValue(
          opacity,
          getAnimatableValue(opacity, timeStart),
          timeStart,
          true
        )
      }
      if (endKeyframe == null) {
        endKeyframe = setAnimatableValue(
          opacity,
          getAnimatableValue(opacity, timeEnd),
          timeEnd,
          true
        )
      }

      startKeyframe.updateComponent(NumberValueComponent, startOpacity)
      endKeyframe.updateComponent(NumberValueComponent, endOpacity)

      startKeyframe.updateComponent(TimingCurveComponent, {
        out: {
          x: 0.42,
          y: 0,
        },
        in: {
          x: 1 - 0.42,
          y: 1,
        },
      })
      endKeyframe.updateComponent(TimingCurveComponent, {
        out: {
          x: 0.42,
          y: 0,
        },
        in: {
          x: 1 - 0.42,
          y: 1,
        },
      })
    }

    disappear: Type['disappear'] = ({ timeStart, timeEnd }) => {
      setEndTime(this.entity, timeEnd)

      const keyframesInRange = getSortedKeyframes(
        this.entity.getComponentOrThrow(OpacityComponent)
      ).filter((keyframe) => {
        const time = keyframe.getComponentOrThrow(TimeComponent).value
        return time >= timeStart && time <= timeEnd
      })
      if (keyframesInRange.length > 0) {
        return
      }

      const opacity = this.entity.getComponentOrThrow(OpacityComponent)
      const startOpacity = getAnimatableValue(opacity, timeStart)
      const endOpacity = 0

      let startKeyframe = keyframesInRange[0]
      let endKeyframe = keyframesInRange[keyframesInRange.length - 1]

      // @NOTE: stop processing if layer is already hidden
      if (
        startKeyframe != null &&
        startKeyframe.getComponentOrThrow(NumberValueComponent).value ===
          endOpacity
      ) {
        return
      }

      if (startKeyframe == null) {
        startKeyframe = setAnimatableValue(
          opacity,
          getAnimatableValue(opacity, timeStart),
          timeStart,
          true
        )
      }
      if (endKeyframe == null) {
        endKeyframe = setAnimatableValue(
          opacity,
          getAnimatableValue(opacity, timeEnd),
          timeEnd,
          true
        )
      }

      startKeyframe.updateComponent(NumberValueComponent, startOpacity)
      endKeyframe.updateComponent(NumberValueComponent, endOpacity)

      startKeyframe.updateComponent(TimingCurveComponent, {
        out: {
          x: 0.42,
          y: 0,
        },
        in: {
          x: 1 - 0.42,
          y: 1,
        },
      })
      endKeyframe.updateComponent(TimingCurveComponent, {
        out: {
          x: 0.42,
          y: 0,
        },
        in: {
          x: 1 - 0.42,
          y: 1,
        },
      })
    }
  }
}
