// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MxEy57CilgU7zFpmpSOA{display:flex;flex-direction:column;gap:8px;width:240px;padding:8px;color:var(--figma-color-text);background:var(--figma-color-bg)}.gs5k7G3qeUy5ksDpR9Lg{display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:0px 0 0px 4px}.HqbZhRxzooCNuEKVLQVg{padding:0 4px}.jB9izBFhwxU2Jf3wE4B_{font-weight:600;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0}.fPzPGv0lHHz6FlSbKtLm{display:flex;flex-direction:row;align-items:center;width:100%}.sD5k86m5_vAP6yvutQkG{flex:1;padding:0 6px}._ZKweEhSmMwX2Sn02yCw{width:fit-content}.h7mbiUfNMv6YwLaEXQhm{display:flex;flex-direction:column;gap:8px}.mQ3MBSzkN6Cqqky87cgA{width:100%;padding:0 8px}.TtCYG3DaKaYREVIQrIkT{width:100%}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/properties-panel/components/common/edit-curve-style/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,WAAA,CACA,WAAA,CACA,6BCgIe,CD/Hf,gCCPa,CDUf,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CACA,qBAAA,CAGF,sBACE,aAAA,CAGF,sBEyEE,eAAA,CACA,cAAA,CACA,8BCnGa,CDoGb,gBAAA,CACA,qBAAA,CF3EA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,UAAA,CAEA,sBACE,MAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,UAAA,CACA,aAAA,CAEF,sBACE,UAAA","sourceRoot":""}]);
// Exports
export var container = `MxEy57CilgU7zFpmpSOA`;
export var header = `gs5k7G3qeUy5ksDpR9Lg`;
var _1 = `HqbZhRxzooCNuEKVLQVg`;
export { _1 as "label-input" };
export var title = `jB9izBFhwxU2Jf3wE4B_`;
var _2 = `fPzPGv0lHHz6FlSbKtLm`;
export { _2 as "default-presets-wrapper" };
var _3 = `sD5k86m5_vAP6yvutQkG`;
export { _3 as "default-presets-wrapper__select" };
var _4 = `_ZKweEhSmMwX2Sn02yCw`;
export { _4 as "default-presets-wrapper__toggle" };
export var rows = `h7mbiUfNMv6YwLaEXQhm`;
var _5 = `mQ3MBSzkN6Cqqky87cgA`;
export { _5 as "delete-button-wrapper" };
var _6 = `TtCYG3DaKaYREVIQrIkT`;
export { _6 as "delete-button" };
export default ___CSS_LOADER_EXPORT___;
