import { Project } from '@aninix-inc/model'
import { applyMatchingEntitiesValues } from '../overrides-export/utils/apply-matching-entities-values'
import { createEmptyProject } from '../overrides-export/utils/create-empty-project'

export function makeOverrideProject({
  override,
  project,
}: {
  override: {
    id: string
    projectId: string
    snapshot: { [key: string]: unknown }
    name?: string | undefined
    createdAt: string
    updatedAt?: string | undefined
  }
  project: Project
}) {
  const original = createEmptyProject(override.id).applySnapshot(
    project.getSnapshot()
  )

  const overrideProject = createEmptyProject(override.id).applySnapshot(
    override.snapshot as any
  )

  applyMatchingEntitiesValues({
    project: original,
    sourceOfChanges: overrideProject,
  })

  return original
}
