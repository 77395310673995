// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bGWNjsLE7g7q_LZ3m2MI{--figma-color-text: #ffffff;z-index:20;display:flex;flex-direction:row;flex-wrap:nowrap;gap:16px;align-items:stretch;justify-content:space-between;height:48px;background-color:#2c2c2c}.CBAYQP7H3hQjFhdW5lWf{display:flex;flex:1;flex-direction:row;flex-shrink:0;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start}.HZXlGX7NxOHOkLHHkuq7{display:flex;flex:3;flex-direction:row;flex-shrink:1;align-items:center;justify-content:center}.aDhHJw_XnjDjVLRIiCBb{display:flex;flex-direction:row;flex-shrink:0;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start}.uTzZdcaONCMyWZI571jl{padding:8px 9px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/history/toolbar-legacy/router.scss","webpack://./packages/app-design-system/src/theme/colors.scss"],"names":[],"mappings":"AAQA,sBACE,2BAAA,CACA,UAAA,CAEA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,QAAA,CACA,mBAAA,CACA,6BAAA,CAEA,WAjBiB,CAkBjB,wBC2BmB,CDxBrB,sBACE,YAAA,CACA,MAAA,CACA,kBAAA,CACA,aAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CAGF,sBACE,YAAA,CACA,MAAA,CACA,kBAAA,CACA,aAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,aAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CAGF,sBACE,eAAA","sourceRoot":""}]);
// Exports
export var containerHeight = `48px`;
export var container = `bGWNjsLE7g7q_LZ3m2MI`;
export var left = `CBAYQP7H3hQjFhdW5lWf`;
export var center = `HZXlGX7NxOHOkLHHkuq7`;
export var right = `aDhHJw_XnjDjVLRIiCBb`;
var _1 = `uTzZdcaONCMyWZI571jl`;
export { _1 as "export" };
export default ___CSS_LOADER_EXPORT___;
