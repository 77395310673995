import UaParser from 'ua-parser-js'
// @ts-ignore
import packageFile from '../../../package.json'

// @ts-ignore
const version = packageFile.versions['web-app']

export enum Os {
  Windows = 'Windows',
  Mac = 'Mac OS',
  Linux = 'Linux',
  Unknown = 'Unknown',
}

export enum Env {
  Local = 'local',
  Development = 'dev',
  Production = 'prod',
}

export enum LogLevel {
  /**
   * @description includes all logs levels
   */
  Debug = 'debug',

  /**
   * @description includes only user logs level
   */
  Info = 'info',

  /**
   * @description includes only errors
   */
  Error = 'error',
}

const getOs = (): Os => {
  const preparedUserAgent = window.navigator.userAgent.toLowerCase()

  if (preparedUserAgent.includes('windows')) {
    return Os.Windows
  }

  if (preparedUserAgent.includes('mac')) {
    return Os.Mac
  }

  if (preparedUserAgent.includes('linux')) {
    return Os.Linux
  }

  return Os.Unknown
}

const getEngine = (): string => {
  const uaParser = new UaParser()
  return uaParser.getBrowser().name || ''
}

interface IConfig {
  version: string
  apiUrl: string
  websiteUrl: string
  webAppUrl: string
  logLevel: LogLevel
  env: Env
  getOs: () => Os
  getEngine: () => string
  maintenance: boolean
}

export const config: IConfig = {
  version,
  apiUrl: <string>process.env.API_URL,
  websiteUrl: <string>process.env.WEBSITE_URL,
  webAppUrl: <string>process.env.WEB_APP_URL,
  logLevel: <LogLevel>process.env.LOG_LEVEL,
  env: <Env>process.env.ENV,
  getOs,
  getEngine,
  maintenance: false,
}
