import {
  AnalyticsProvider,
  LocalAnalytics,
  NetworkAnalytics,
} from '@aninix/analytics'
import { ClipboardProvider, LocalClipboard } from '@aninix/clipboard'
import {
  SettingsProvider,
  ToolsProvider,
  UserRole,
  featureFlags,
} from '@aninix/core'
import { ProjectHistory } from '@aninix/editor/modules/history'
import { LocalLogger, LoggerProvider } from '@aninix/logger'
import { Notifications, NotificationsProvider } from '@aninix/notifications'
import { StylesProvider } from '@material-ui/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { MemberList, TeamSettings } from './components'
import { JoinTeam } from './components/join-team'
import { LottieList } from './components/lottie-list'
import { ProjectsList } from './components/projects-list'

import { QuickExport } from '@aninix/core/modules/quick-export'
import * as Sentry from '@sentry/react'
import { TrashProjectsList } from './components/projects-list/trash-project-list'
import { Env, config } from './config'
import { handleColorScheme } from './handle-color-scheme'
import { Auth } from './modules/auth'
import { IdentifyUser } from './modules/identify-user'
import { Inspector } from './modules/inspector'
import { Login } from './modules/login'
import { Logout } from './modules/logout'
import { LottiePreview } from './modules/lottie-preview'
import { ManageSubscription } from './modules/manage-subscription'
import { MyPage } from './modules/my-page'
import { NewTeam } from './modules/new-team'
import { NotFoundView } from './modules/not-found-view'
import { Payment } from './modules/payment'
import { Preview } from './modules/preview'
import { SendFeedbackPage } from './modules/send-feedback-page'
import { RedirectToTeam, Teams, TeamsWithoutUi } from './modules/teams'
import { TestPayments } from './modules/test-payments'
import { Toasts, toast } from './modules/toasts'
import { Upgrade } from './modules/upgrade'
import { localStorageIo } from './services'
import { authBroadcastChannel } from './shared'
import { SessionStoreProvider } from './stores'
import { SessionStore, useSessionStore } from './stores/session'
import { CurrentSpaceStore } from './use-cases'
import { getRenewedToken } from '@aninix/api/get-renewed-token'
import { setHttpClientToken } from '@aninix/api'

handleColorScheme()

const Editor = React.lazy(() => import('./modules/editor'))

const queryClient = new QueryClient()
const analyticsClient =
  config.env === Env.Production
    ? new NetworkAnalytics({ apiUrl: config.apiUrl })
    : new LocalAnalytics()
const loggerClient = new LocalLogger({ level: config.logLevel })
const clipboardClient = new LocalClipboard({ logger: loggerClient })

if (config.env === Env.Production) {
  Sentry.init({
    dsn: 'https://018bb42b8953952722409ad517c8ae5f@o4504077571457024.ingest.us.sentry.io/4507851087675392',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https?:\/\/(api|api-dev|app)\.aninix\.com/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

class ToastNotifications implements Notifications {
  showNotification: Notifications['showNotification'] = (content, options) => {
    toast(content, {
      variant: options?.variant === 'error' ? 'error' : 'info',
      toastProps: {
        autoClose: options?.autoClose,
        toastId: options?.id ?? Math.random(),
      },
    })
  }
}
const notificationsClient = new ToastNotifications()

// @NOTE: required to properly throw async errors inside of React context
function useAsyncError() {
  const [_, setError] = React.useState()
  return React.useCallback(
    (e: any) => {
      setError(() => {
        throw e
      })
    },
    [setError]
  )
}

function useQuery() {
  return React.useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    return Object.fromEntries(params.entries())
  }, [])
}

function withoutQueryParam(url: string, paramKey: string) {
  const urlObject = new URL(url)
  const searchParams = urlObject.searchParams
  searchParams.delete(paramKey)
  urlObject.search = searchParams.toString()
  return urlObject.toString()
}

const AnonymousRoute: React.FCC = observer(({ children }) => {
  const sessionStore = useSessionStore()

  if (sessionStore.role !== UserRole.Anonymous) {
    return <Navigate to="/" replace />
  }

  return <>{children}</>
})

// @ts-ignore
const ProtectedRoute: React.FCC = observer(({ children }) => {
  const sessionStore = useSessionStore()
  const { pathname } = useLocation()

  if (sessionStore.role === UserRole.Anonymous) {
    const urlToRedirect = encodeURIComponent(pathname)
    return (
      <Navigate to={`/login?redirectUrlOnSuccess=${urlToRedirect}`} replace />
    )
  }

  return children
})

export const App: React.FC = observer(() => {
  const throwError = useAsyncError()
  // @NOTE: if user id provided in query then we set it as active
  const { aa: activeAccountId, token: queryToken } = useQuery()
  const sessionStore = React.useRef(
    new SessionStore({
      localStorage: localStorageIo,
      logger: loggerClient,
    })
  )

  const handleQueries = async () => {
    if (queryToken != null) {
      setHttpClientToken(queryToken)
      window.history.replaceState(
        null,
        '',
        withoutQueryParam(window.location.href, 'token')
      )

      try {
        const {
          data: { userId, token },
        } = await getRenewedToken()

        if (userId == null || token == null) return

        const oldStorage = await localStorageIo.get()

        if (oldStorage == null) {
          localStorageIo.set({
            aa: userId,
            t: { [userId]: token },
          })
        } else {
          localStorageIo.set({
            aa: oldStorage.aa,
            t: { ...oldStorage.t, [userId]: token },
          })
        }
      } catch (e) {}
    }

    sessionStore.current
      .initiate(activeAccountId)
      .then(() => {
        window.history.replaceState(
          null,
          '',
          withoutQueryParam(window.location.href, 'aa')
        )
      })
      .catch(throwError)
  }

  React.useEffect(() => {
    handleQueries()
  }, [])

  // @NOTE: reload on any authentication event
  React.useEffect(() => {
    authBroadcastChannel.addEventListener('message', (event) => {
      if (
        event.data === 'logged-out' &&
        sessionStore.current.role !== UserRole.Anonymous
      ) {
        window.location.reload()
        return
      }

      if (
        event.data === 'authenticated' &&
        sessionStore.current.role === UserRole.Anonymous
      ) {
        window.location.reload()
        return
      }
    })

    return () => {
      authBroadcastChannel.close()
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <Toasts />

        <AnalyticsProvider client={analyticsClient}>
          <ClipboardProvider client={clipboardClient}>
            <LoggerProvider client={loggerClient}>
              <NotificationsProvider client={notificationsClient}>
                <SessionStoreProvider store={sessionStore.current}>
                  <ToolsProvider>
                    <SettingsProvider>
                      <IdentifyUser>
                        <CurrentSpaceStore>
                          <Routes>
                            <Route
                              path="/"
                              element={<Navigate to="/my" replace />}
                            />
                            <Route
                              path="/lottie-preview"
                              element={<LottiePreview />}
                            />
                            <Route
                              path="/login"
                              element={
                                <AnonymousRoute>
                                  <Login />
                                </AnonymousRoute>
                              }
                            />
                            <Route
                              path="/login/new"
                              element={<Login addAccount />}
                            />
                            <Route
                              path="/logout"
                              element={
                                <ProtectedRoute>
                                  <Logout />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="/quick-export"
                              element={<QuickExport />}
                            />
                            <Route
                              path="/inspect/:projectId"
                              element={<Navigate to="./details" />}
                            />
                            <Route
                              path="/inspect/:projectId/:inspectMode"
                              element={<Inspector />}
                            />
                            <Route
                              path="/preview/:projectId"
                              element={<Preview />}
                            />
                            <Route path="/auth" element={<Auth />} />
                            {/* @TODO: check and remove if it's not using */}
                            <Route path="/payment" element={<Payment />} />
                            <Route
                              path="/manage-subscription"
                              element={
                                <ProtectedRoute>
                                  <ManageSubscription />
                                </ProtectedRoute>
                              }
                            />

                            {/* @NOTE: required to use WhatsNew + outlet to correctly render children */}
                            {/* @NOTE: temporary disable what's new module to improve UX */}
                            {/* <Route element={<WhatsNew />}> */}
                            <Route
                              path="/edit/:projectId"
                              element={
                                <ProtectedRoute>
                                  <React.Suspense>
                                    <Editor />
                                  </React.Suspense>
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="/edit/:projectId/comments"
                              element={
                                <ProtectedRoute>
                                  <Editor />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path="/history/:projectId"
                              element={
                                <ProtectedRoute>
                                  <ProjectHistory />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path="/my"
                              element={
                                <ProtectedRoute>
                                  <MyPage />
                                </ProtectedRoute>
                              }
                            >
                              <Route
                                index
                                element={<Navigate to="projects" replace />}
                              />
                              <Route
                                path="projects/:folderId?"
                                element={<ProjectsList page="my" />}
                              />
                              <Route
                                path="trash"
                                element={<TrashProjectsList page="my" />}
                              />
                              <Route path="lotties" element={<LottieList />} />
                            </Route>

                            {/* @NOTE: teams */}
                            <Route
                              path="/teams/new"
                              element={
                                <ProtectedRoute>
                                  <NewTeam />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="/teams"
                              element={
                                <ProtectedRoute>
                                  <RedirectToTeam />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path="/teams/:teamId"
                              element={
                                <ProtectedRoute>
                                  <Teams />
                                </ProtectedRoute>
                              }
                            >
                              <Route
                                path=""
                                element={<Navigate to="projects" />}
                              />
                              <Route
                                path="projects/:folderId?"
                                element={<ProjectsList page="teams" />}
                              />
                              <Route path="people" element={<MemberList />} />
                              <Route
                                path="trash"
                                element={<TrashProjectsList page="teams" />}
                              />
                              <Route
                                path="settings"
                                element={<TeamSettings />}
                              />
                            </Route>

                            <Route
                              path="/teams/:teamId/manage-subscription"
                              element={
                                <ProtectedRoute>
                                  <TeamsWithoutUi>
                                    <ManageSubscription />
                                  </TeamsWithoutUi>
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path="/join-team/:teamId"
                              element={
                                <ProtectedRoute>
                                  <JoinTeam />
                                </ProtectedRoute>
                              }
                            />

                            <Route
                              path="/upgrade"
                              element={
                                <ProtectedRoute>
                                  <Upgrade />
                                </ProtectedRoute>
                              }
                            />

                            {featureFlags.feedbackPage && (
                              <Route
                                path="feedback"
                                element={<SendFeedbackPage />}
                              />
                            )}

                            {config.env === Env.Development && (
                              <Route
                                path="test-payments"
                                element={<TestPayments />}
                              />
                            )}

                            <Route path="*" element={<NotFoundView />} />
                          </Routes>
                        </CurrentSpaceStore>
                      </IdentifyUser>
                    </SettingsProvider>
                  </ToolsProvider>
                </SessionStoreProvider>
              </NotificationsProvider>
            </LoggerProvider>
          </ClipboardProvider>
        </AnalyticsProvider>
      </StylesProvider>
    </QueryClientProvider>
  )
})
