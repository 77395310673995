// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LYjaj6xkMX7aD1_j0NT3{position:relative;top:0;z-index:1;display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start;height:14px}.LwMncbgX_11OrNnfYCRU{flex-shrink:0;width:6px;height:100%;margin:0;padding:0;background-color:var(--figma-color-icon-onselected-secondary);border:none;cursor:ew-resize}.LwMncbgX_11OrNnfYCRU:focus{outline:none}.HTBR5ht3l3MLFy6zRgl_{border-radius:2px 0 0 2px}.N5B8Zm4JsTYnjKfDvsh_{border-radius:0 2px 2px 0}.jrl6auqnhhI7M94Be3la{height:100%;margin:0;padding:0;background-color:var(--figma-color-text-disabled);border:none;cursor:default}.jrl6auqnhhI7M94Be3la:focus{outline:none}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/visible-range/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,KAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CACA,WAAA,CAGF,sBACE,aAAA,CAEA,SAAA,CACA,WAAA,CAEA,QAAA,CACA,SAAA,CACA,6DCuFkC,CDtFlC,WAAA,CACA,gBAAA,CAEA,4BACE,YAAA,CAIJ,sBACE,yBAAA,CAGF,sBACE,yBAAA,CAGF,sBACE,WAAA,CAEA,QAAA,CACA,SAAA,CACA,iDCuGuB,CDtGvB,WAAA,CACA,cAAA,CAEA,4BACE,YAAA","sourceRoot":""}]);
// Exports
export var container = `LYjaj6xkMX7aD1_j0NT3`;
export var handler = `LwMncbgX_11OrNnfYCRU`;
var _1 = `HTBR5ht3l3MLFy6zRgl_`;
export { _1 as "handler-left" };
var _2 = `N5B8Zm4JsTYnjKfDvsh_`;
export { _2 as "handler-right" };
export var track = `jrl6auqnhhI7M94Be3la`;
export default ___CSS_LOADER_EXPORT___;
