// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k4SDLrS1iYn1utwrgAr4{position:absolute;top:2px;left:0px;z-index:101;display:flex;flex-direction:column;align-items:center;justify-content:stretch;height:100%;pointer-events:none}.zgI8G7gQ930pYTsTSyxt{width:15px;height:16px;padding:0;background:none;border:none;outline:none;cursor:default;pointer-events:all}.yZu2IACyD2t8tuGJu9Qc{width:1px;height:100%;background-color:var(--figma-color-bg-brand)}.mGUfX_Yxi80L1RnkAljs{font-weight:500;font-size:9px;font-family:"Inter",sans-serif;line-height:12px;letter-spacing:.005em;position:absolute;top:0;left:50%;min-width:32px;margin:0;padding:2px 3px;color:#fff;background-color:var(--figma-color-bg-brand);border-radius:4px;transform:translateX(-50%)}.jw3OC7bAnrUchp3VD01p{width:100%;margin:0;padding:0;text-align:center}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/time-slider/time-slider-view.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/colors.scss"],"names":[],"mappings":"AAIA,sBACE,iBAAA,CAEA,OAAA,CACA,QAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,uBAAA,CAEA,WAAA,CACA,mBAAA,CAGF,sBACE,UAlBM,CAmBN,WAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CAEA,cAAA,CAEA,kBAAA,CAGF,sBACE,SAAA,CACA,WAAA,CACA,4CChCkB,CDmCpB,sBEIE,eAAA,CACA,aAAA,CACA,8BC5Ca,CD6Cb,gBAAA,CACA,qBAAA,CFLA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,cAAA,CACA,QAAA,CACA,eAAA,CACA,UIpBiB,CJsBjB,4CC9CkB,CDgDlB,iBAAA,CACA,0BAAA,CAEA,sBACE,UAAA,CACA,QAAA,CACA,SAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var container = `k4SDLrS1iYn1utwrgAr4`;
export var handler = `zgI8G7gQ930pYTsTSyxt`;
export var marker = `yZu2IACyD2t8tuGJu9Qc`;
export var label = `mGUfX_Yxi80L1RnkAljs`;
var _1 = `jw3OC7bAnrUchp3VD01p`;
export { _1 as "label-text" };
export default ___CSS_LOADER_EXPORT___;
