import UaParser from 'ua-parser-js'

// @ts-ignore
import packageFile from '../../../package.json'

export enum Env {
  Local = 'local',
  Development = 'dev',
  Production = 'prod',
}

export enum LogLevel {
  /**
   * @description includes all logs levels
   */
  Debug = 'debug',

  /**
   * @description includes only user logs level
   */
  Info = 'info',

  /**
   * @description includes only errors
   */
  Error = 'error',
}

export const logLevel: LogLevel = process.env.LOG_LEVEL as LogLevel

export const env: Env = <Env>process.env.ENV

// @ts-ignore
const version = packageFile.versions['figma-plugin']

export enum Os {
  Windows = 'Windows',
  Mac = 'Mac OS',
  Linux = 'Linux',
  Unknown = 'Unknown',
}

const getOs = (): Os => {
  const preparedUserAgent = window.navigator.userAgent.toLowerCase()

  if (preparedUserAgent.includes('windows')) {
    return Os.Windows
  }

  if (preparedUserAgent.includes('mac')) {
    return Os.Mac
  }

  if (preparedUserAgent.includes('linux')) {
    return Os.Linux
  }

  return Os.Unknown
}

const getEngine = (): string => {
  const uaParser = new UaParser()
  return uaParser.getBrowser().name || ''
}

interface IConfig {
  environment: 'browser' | 'plugin'
  version: string
  assetsUrl: string
  webAppUrl: string
  getOs: () => Os
  getEngine: () => string
  /**
   * @description number of projects free users can publish to web
   */
  publishedProjectsLimit: number
  /**
   * @description number of projects free users can use locally in Figma file
   */
  localProjectsLimit: number
  /**
   * @description number of layers in frame at which we start showing progress bar
   */
  layersLimitToShowProgress: number
  /**
   * @description number of simultaneously active projects
   */
  activeProjectsLimit: number
}

const productionConfig: IConfig = {
  environment: process.env.ENVIRONMENT === 'browser' ? 'browser' : 'plugin',
  version,
  assetsUrl: 'https://app.aninix.com',
  webAppUrl: 'https://app.aninix.com',
  getOs,
  getEngine,
  publishedProjectsLimit: 1,
  localProjectsLimit: 3,
  layersLimitToShowProgress: 100,
  activeProjectsLimit: 5,
}

const devConfig: IConfig = {
  environment: process.env.ENVIRONMENT === 'browser' ? 'browser' : 'plugin',
  version,
  assetsUrl: '',
  webAppUrl: 'https://dev-app.aninix.com',
  getOs,
  getEngine,
  publishedProjectsLimit: 1,
  localProjectsLimit: 3,
  layersLimitToShowProgress: 100,
  activeProjectsLimit: 5,
}

export const config = (() => {
  if (env === Env.Production) {
    return productionConfig
  }

  if (env === Env.Local) {
    // @TODO: add localConfig export
    // return localConfig
    return devConfig
  }

  return devConfig
})()
