// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wguClTveOzEmXPlVP2eK{display:flex;flex-direction:column;gap:8px;width:100%}.PAw1J1XBz7eJYHG798Tc{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;padding:0 8px 8px 8px;color:var(--figma-color-text-disabled)}.moYkfcM9drqicSDS6aL_{display:flex;flex-direction:row;align-items:stretch;justify-content:space-between;width:100%}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/properties-panel/components/nodes/effects/index.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,UAAA,CAGF,sBCEE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CDJA,QAAA,CACA,qBAAA,CACA,sCGsIuB,CHnIzB,sBACE,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,6BAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var container = `wguClTveOzEmXPlVP2eK`;
export var empty = `PAw1J1XBz7eJYHG798Tc`;
export var row = `moYkfcM9drqicSDS6aL_`;
export default ___CSS_LOADER_EXPORT___;
