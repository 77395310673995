// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mVvISbqhGIstSKIkTgLm{padding:8px 16px;border-bottom:1px solid var(--aninix-color-foreground-black-05)}.wceB352imOsH5MCGm66M{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start}.Yu3uqGDl8ltlCkRI310o{display:flex;flex:1;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:center;width:calc(33.3333333333% - 4px);margin-right:4px;padding:4px;color:var(--figma-color-text);background:none;border:1px solid rgba(0,0,0,0);border-radius:4px;outline:none;cursor:default;font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}.Yu3uqGDl8ltlCkRI310o:nth-last-child(-n+1){margin-right:0}.Yu3uqGDl8ltlCkRI310o:hover{border-color:var(--aninix-color-foreground-black-1)}.Yu3uqGDl8ltlCkRI310o span{margin-left:8px}.APQTIOF0He26ECkGKm_C{background-color:var(--aninix-color-foreground-black-1)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/animation-presets/view-preset-type.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss"],"names":[],"mappings":"AAEA,sBACE,gBAAA,CACA,+DAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CAGF,sBACE,YAAA,CACA,MAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CACA,gCAAA,CACA,gBAAA,CACA,WAAA,CACA,6BC+Ge,CD9Gf,eAAA,CACA,8BAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CEnBA,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CFkBA,2CACE,cAAA,CAGF,4BACE,mDIlCkB,CJqCpB,2BACE,eAAA,CAGF,sBACE,uDI1CkB","sourceRoot":""}]);
// Exports
export var container = `mVvISbqhGIstSKIkTgLm`;
export var buttons = `wceB352imOsH5MCGm66M`;
export var button = `Yu3uqGDl8ltlCkRI310o`;
var _1 = `APQTIOF0He26ECkGKm_C`;
export { _1 as "button--highlighted" };
export default ___CSS_LOADER_EXPORT___;
