// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xr5ApqNX70Qj5UzSjErk{position:relative;z-index:1}.mgofiaKnRsVG15VjqxWF{font-weight:700;font-size:8px;font-family:"Inter",sans-serif;line-height:13px;letter-spacing:.005em;position:absolute;z-index:2;width:fit-content;margin:0;padding:0 2px;color:#fff;background-color:var(--figma-color-bg-brand);border-radius:2px;transform:translate(-50%, -50%);pointer-events:none}.pFW8ChTRiJHXrZflLCSq{background-color:#b3b3b3}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/badge/index.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/colors.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,SAAA,CAGF,sBC0EE,eAAA,CACA,aAAA,CACA,8BCnFa,CDoFb,gBAAA,CACA,qBAAA,CD5EA,iBAAA,CACA,SAAA,CACA,iBAAA,CACA,QAAA,CACA,aAAA,CACA,UGaiB,CHZjB,4CIZkB,CJalB,iBAAA,CACA,+BAAA,CACA,mBAAA,CAEA,sBACE,wBGkBiB","sourceRoot":""}]);
// Exports
export var container = `Xr5ApqNX70Qj5UzSjErk`;
export var label = `mgofiaKnRsVG15VjqxWF`;
var _1 = `pFW8ChTRiJHXrZflLCSq`;
export { _1 as "label--dimmed" };
export default ___CSS_LOADER_EXPORT___;
