import {
  ColorStopsRelationsAspect,
  HashComponent,
  NumberKeyframe,
  PaintType,
  PaintTypeComponent,
  ProgressComponent,
  RgbaKeyframe,
  RgbaValueComponent,
  TimeComponent,
  getSortedKeyframes,
  getValueRgba,
} from '@aninix-inc/model'

import { Entity } from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { PaintSnapshot } from '@aninix-inc/renderer'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getPaintsKeyframesType } from '../../../utils/getPaintsKeyframesType'
import { GradientValue } from '../../values/gradient'
import { ImageValue } from '../../values/image'
import { RgbaValue } from '../../values/rgba'
import { KeyframesPropertyControl } from '../keyframes-property-control'
import * as styles from '../paints/index.scss'

export const Paint: React.FC<{ paints: Entity[] }> = ({ paints }) => {
  const {
    time,
    entitiesWithPaints: nodes,
    snapshots,
  } = useNodePropertiesPanel()
  useEntities(nodes)
  const [isEditable, setIsEditable] = React.useState<boolean>(false)
  useEntities(paints)
  const type = paints[0].getComponentOrThrow(PaintTypeComponent).value
  const keyframesTypes = getPaintsKeyframesType(paints, time)

  const paintSnapshots = snapshots.flatMap((s) =>
    [s.fills, s.strokes]
      .flat()
      .filter((item) => paints.some((p) => p.id === item.id))
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <PaintEditable paints={paints} time={time} type={type} />
      ) : (
        <PaintDisplay
          paints={paints}
          time={time}
          type={type}
          keyframesTypes={keyframesTypes}
          paintSnapshots={paintSnapshots}
        />
      )}
    </div>
  )
}

Paint.displayName = 'Paint'

const PaintEditable: React.FC<{
  paints: Entity[]
  time: number
  type: PaintType
}> = ({ paints, time, type }) => {
  switch (type) {
    case PaintType.Solid: {
      return (
        <div className="flex w-full justify-between">
          <RgbaValue
            components={paints.map((f) =>
              f.getComponentOrThrow(RgbaValueComponent)
            )}
            time={time}
          />

          <KeyframesPropertyControl
            components={paints.map((f) =>
              f.getComponentOrThrow(RgbaValueComponent)
            )}
            time={time}
            KeyframeConstructor={RgbaKeyframe}
            valueGetter={getValueRgba}
          />
        </div>
      )
    }

    case PaintType.GradientLinear:
    case PaintType.GradientRadial: {
      const colorStops = paints.flatMap((p) =>
        p
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()
          .flatMap((colorStop) => [
            colorStop.getComponentOrThrow(RgbaValueComponent),
          ])
      )

      // @NOTE: don't render gradient if it doesn't have any color stops.
      // Related to ANI-1529.
      if (colorStops.length === 0) {
        return (
          <div className="flex w-full justify-between">
            <p className={styles.mixed}>
              Invalid data received from Figma. Please try to sync your project
              again. If the issue keeps happening,{' '}
              <a href="mailto:info@aninix.com" className="underline">
                contact us
              </a>
              .
            </p>
          </div>
        )
      }

      return (
        <div className="flex w-full justify-between">
          <GradientValue paints={paints} time={time} />

          <KeyframesPropertyControl
            components={colorStops}
            time={time}
            KeyframeConstructor={RgbaKeyframe}
            valueGetter={getValueRgba}
            // Related to onToggle description.
            // This is a dirty hack required to fix the ANI-1170 task.
            onToggle={() => {
              const project = paints[0].getProjectOrThrow()

              const progresses = paints.flatMap((p) =>
                p
                  .getAspectOrThrow(ColorStopsRelationsAspect)
                  .getChildrenList()
                  .map((colorStop) =>
                    colorStop.getComponentOrThrow(ProgressComponent)
                  )
              )

              progresses.map((progress) => {
                const keyframes = getSortedKeyframes(progress)
                const keyframeAtTime = keyframes.find(
                  (k) => k.getComponentOrThrow(TimeComponent).value === time
                )
                if (keyframeAtTime) {
                  project.removeEntity(keyframeAtTime.id)
                  return
                }
                project.createEntity(NumberKeyframe, {
                  target: progress,
                  value: progress.value,
                  time,
                })
              })
            }}
          />
        </div>
      )
    }

    case PaintType.Image: {
      return (
        <ImageValue
          components={paints.map((p) => p.getComponentOrThrow(HashComponent))}
        />
      )
    }
  }
}

PaintEditable.displayName = 'PaintEditable'

interface PaintDisplayProps {
  paints: Entity[]
  time: number
  type: PaintType
  keyframesTypes: PropertyKeyframesType[]
  paintSnapshots: PaintSnapshot[]
}

const propsAreEqual = (prev: PaintDisplayProps, next: PaintDisplayProps) => {
  if (prev.paints.length !== next.paints.length) return false
  if (prev.keyframesTypes.length !== next.keyframesTypes.length) return false
  for (let i = 0; i < prev.paints.length; i += 1) {
    if (prev.paints[i].id !== next.paints[i].id) return false
  }
  for (let i = 0; i < prev.keyframesTypes.length; i += 1) {
    if (prev.keyframesTypes[i] !== next.keyframesTypes[i]) return false
  }
  if (prev.paintSnapshots.length !== next.paintSnapshots.length) return false
  for (let i = 0; i < prev.paintSnapshots.length; i += 1) {
    if (prev.paintSnapshots[i].id !== next.paintSnapshots[i].id) return false
  }
  if (!R.equals(prev.paintSnapshots, next.paintSnapshots)) return false

  return true
}

export const PaintDisplay: React.FC<PaintDisplayProps> = React.memo(
  ({ paints, time, type, keyframesTypes }) => {
    switch (type) {
      case PaintType.Solid: {
        return (
          <div className="flex w-full justify-between">
            <RgbaValue
              components={paints.map((f) =>
                f.getComponentOrThrow(RgbaValueComponent)
              )}
              time={time}
            />

            <KeyframesPropertyControl
              components={paints.map((f) =>
                f.getComponentOrThrow(RgbaValueComponent)
              )}
              time={time}
              KeyframeConstructor={RgbaKeyframe}
              valueGetter={getValueRgba}
            />
          </div>
        )
      }

      case PaintType.GradientLinear:
      case PaintType.GradientRadial: {
        const colorStops = paints.flatMap((p) =>
          p
            .getAspectOrThrow(ColorStopsRelationsAspect)
            .getChildrenList()
            .flatMap((colorStop) => [
              colorStop.getComponentOrThrow(RgbaValueComponent),
            ])
        )

        // @NOTE: don't render gradient if it doesn't have any color stops.
        // Related to ANI-1529.
        if (colorStops.length === 0) {
          return (
            <div className="flex w-full justify-between">
              <p className={styles.mixed}>
                Invalid data received from Figma. Please try to sync your
                project again. If the issue keeps happening,{' '}
                <a href="mailto:info@aninix.com" className="underline">
                  contact us
                </a>
                .
              </p>
            </div>
          )
        }

        return (
          <div className="flex w-full justify-between">
            <GradientValue paints={paints} time={time} />

            <KeyframesPropertyControl
              components={colorStops}
              time={time}
              KeyframeConstructor={RgbaKeyframe}
              valueGetter={getValueRgba}
              // Related to onToggle description.
              // This is a dirty hack required to fix the ANI-1170 task.
              onToggle={() => {
                const project = paints[0].getProjectOrThrow()

                const progresses = paints.flatMap((p) =>
                  p
                    .getAspectOrThrow(ColorStopsRelationsAspect)
                    .getChildrenList()
                    .map((colorStop) =>
                      colorStop.getComponentOrThrow(ProgressComponent)
                    )
                )

                progresses.map((progress) => {
                  const keyframes = getSortedKeyframes(progress)
                  const keyframeAtTime = keyframes.find(
                    (k) => k.getComponentOrThrow(TimeComponent).value === time
                  )
                  if (keyframeAtTime) {
                    project.removeEntity(keyframeAtTime.id)
                    return
                  }
                  project.createEntity(NumberKeyframe, {
                    target: progress,
                    value: progress.value,
                    time,
                  })
                })
              }}
            />
          </div>
        )
      }

      case PaintType.Image: {
        return (
          <ImageValue
            components={paints.map((p) => p.getComponentOrThrow(HashComponent))}
          />
        )
      }
    }
  },
  propsAreEqual
)

PaintDisplay.displayName = 'PaintDisplay'
