import {
  ChildrenRelationsAspect,
  CornerRadiusComponent,
  DurationComponent,
  FillsRelationsAspect,
  Frame,
  Project,
  Rectangle,
  Root,
  SolidPaint,
  StrokeWeightComponent,
  StrokesRelationsAspect,
  SystemsProvider,
  createComponentsProvider,
  createEntitiesProvider,
  getEntryOrThrow,
} from '@aninix-inc/model'
import { Popover } from '@material-ui/core'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { PresetCustomizer } from '../../components/preset-customizer'
import { PresetPreview } from '../../components/preset-preview'
import { Preset, PresetAnimationId } from '../../models/preset'
import * as styles from './view-preset.scss'

const defaultColorTintHex = '#DAEBF7'
const defaultColorHex = '#18A0FB'
const proColorTintHex = '#C6F0DF'
const proColorHex = '#1BC47D'

const defaultColor: RGBA = {
  r: 140,
  g: 207,
  b: 253,
  a: 1,
}
const proColor: RGBA = {
  r: 27,
  g: 196,
  b: 125,
  a: 1,
}

export interface IProps {
  preset: Preset
  /**
   * When returns true then preset is properly applied and UI should be hidden
   */
  onApply: () => boolean
  index: number
  forceApply?: boolean
  isLocked: boolean
}
export const ViewPreset: React.FCC<IProps> = observer(
  ({ preset, forceApply = false, onApply, index, isLocked }) => {
    const [isHovered, setIsHovered] = React.useState(false)
    const [isActive, setIsActive] = React.useState(false)
    const containerRef = React.useRef(null)

    const handleUpdateType = React.useCallback(
      (id: PresetAnimationId, type: string) => {
        preset
          .updateRequiredAnimation({ id, type })
          .updateOptionalAnimation({ id, type })
      },
      [preset]
    )

    const previewProject = React.useMemo(() => {
      const includeTrimPath = R.any(
        (animation) => animation.id === PresetAnimationId.AppearanceTrimPath,
        preset.animations
      )

      const project = new Project({
        componentsProvider: createComponentsProvider(),
        entitiesProvider: createEntitiesProvider(),
        systemsProvider: new SystemsProvider([]),
      })

      const root = project.createEntity(Root)
      root.updateComponent(DurationComponent, preset.duration)

      const rect = project
        .createEntity(Rectangle, {
          anchorPoint: { x: 12, y: 12 },
          position: {
            x: 36,
            y: 12,
            tx1: 36,
            ty1: 12,
            tx2: 36,
            ty2: 12,
          },
          size: { x: 24, y: 24 },
        })
        .updateComponent(CornerRadiusComponent, 4)
      rect.getAspectOrThrow(FillsRelationsAspect).clear()
      rect.getAspectOrThrow(StrokesRelationsAspect).clear()

      const entry = project.createEntity(Frame, {
        isEntry: true,
        size: { x: 96, y: 48 },
      })
      entry.getAspectOrThrow(ChildrenRelationsAspect).addChild(rect)
      entry.getAspectOrThrow(FillsRelationsAspect).clear()
      entry.getAspectOrThrow(StrokesRelationsAspect).clear()

      if (includeTrimPath) {
        rect
          .getAspectOrThrow(StrokesRelationsAspect)
          .addChild(
            project.createEntity(SolidPaint, isLocked ? proColor : defaultColor)
          )
        rect.updateComponent(StrokeWeightComponent, 2)
      } else {
        rect
          .getAspectOrThrow(FillsRelationsAspect)
          .addChild(
            project.createEntity(
              SolidPaint,
              isLocked ? { ...proColor, a: 0.5 } : { ...defaultColor, a: 0.5 }
            )
          )
      }

      preset.apply({
        nodes: entry
          .getAspectOrThrow(ChildrenRelationsAspect)
          .getChildrenList(),
        // @NOTE: for preview we show animation from start
        time: 0,
        preview: true,
      })

      return project
    }, [isLocked, preset])

    const focus = React.useCallback(() => {
      setIsHovered(true)
    }, [])

    const blur = React.useCallback(() => {
      setIsHovered(false)
    }, [])

    const deactivate = React.useCallback(() => {
      setIsActive(false)
    }, [])

    const handleApply = React.useCallback(() => {
      const success = onApply()
      if (success) {
        deactivate()
        blur()
      }
    }, [onApply, deactivate, blur])

    const activate = React.useCallback(() => {
      if (forceApply === false && preset.isCustomizable) {
        setIsActive(true)
        return
      }

      handleApply()
    }, [handleApply, forceApply, preset])

    const handleReset = React.useCallback(() => {
      preset.reset()
    }, [preset])

    const entry = getEntryOrThrow(previewProject)

    return (
      <div>
        <button
          onMouseEnter={focus}
          onClick={activate}
          onMouseLeave={blur}
          ref={containerRef}
          className={styles.wrapper}
          style={
            {
              '--highlight-color': isLocked ? proColorHex : defaultColorHex,
              '--highlight-tint-color': isLocked
                ? proColorTintHex
                : defaultColorTintHex,
            } as React.CSSProperties
          }
        >
          <div
            className={classNames(styles.container, {
              [styles['container--highlighted']]: isActive,
            })}
          >
            <div
              className={styles.content}
              style={{ animationDelay: `${15 * index}ms` }}
            >
              <PresetPreview
                node={entry}
                preset={preset}
                isActive={isActive || isHovered}
              />
            </div>

            {preset.isCustomizable && (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={styles.customizable}
              >
                <rect
                  width="16"
                  height="16"
                  rx="4"
                  className="fill-[var(--highlight-tint-color)]"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.5 6.5C12.5 5.56808 11.8626 4.78503 11 4.56301V3H10V4.56301C9.13739 4.78503 8.5 5.56808 8.5 6.5C8.5 7.43192 9.13739 8.21497 10 8.43699V13H11V8.43699C11.8626 8.21497 12.5 7.43192 12.5 6.5ZM10.5 7.5C11.0523 7.5 11.5 7.05228 11.5 6.5C11.5 5.94772 11.0523 5.5 10.5 5.5C9.94772 5.5 9.5 5.94772 9.5 6.5C9.5 7.05228 9.94772 7.5 10.5 7.5Z"
                  className="fill-[var(--highlight-color)]"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.5 9.5C3.5 10.4319 4.13739 11.215 5 11.437L5 13L6 13L6 11.437C6.86261 11.215 7.5 10.4319 7.5 9.5C7.5 8.56808 6.86261 7.78503 6 7.56301L6 3L5 3L5 7.56301C4.13739 7.78503 3.5 8.56808 3.5 9.5ZM5.5 8.5C4.94772 8.5 4.5 8.94772 4.5 9.5C4.5 10.0523 4.94772 10.5 5.5 10.5C6.05228 10.5 6.5 10.0523 6.5 9.5C6.5 8.94772 6.05229 8.5 5.5 8.5Z"
                  className="fill-[var(--highlight-color)]"
                />
              </svg>
            )}
          </div>
        </button>

        <p className={styles.title}>{preset.title}</p>

        <Popover
          open={isActive}
          onClose={deactivate}
          anchorEl={containerRef.current}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          disableRestoreFocus
          className={styles['popover-container']}
          classes={{
            paper: styles['popover-content'],
          }}
        >
          <PresetCustomizer
            preset={preset.toSnapshot()}
            onSpeedChange={preset.updateSpeed}
            onTimingCurveChange={preset.updateTimingCurve}
            onItemChange={handleUpdateType}
            onApply={handleApply}
            onReset={handleReset}
            applyButtonTitle={isLocked ? 'Unlock' : 'Apply'}
            applyButtonStyle={isLocked ? 'green' : 'blue'}
            resetAllowed={preset.isDirty}
          />
        </Popover>
      </div>
    )
  }
)

ViewPreset.displayName = 'ViewPreset'
