// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NxZka5XlbvsRS4ZhOSvp{transform:translateY(4px)}.sbt4o1vWZp49C7EP7lCI{overflow:visible;background:none;border-radius:12px;box-shadow:none}.lG_fqCv47ZycwKL19KCY{display:flex;flex-direction:column;align-items:center;justify-content:flex-start;width:240px;transform:translateY(4px)}.Xe34ASzzLBSSMj_h4vGP{position:absolute;top:0;right:0;z-index:1;transform:translateX(-22px) translateY(-100%)}.Xe34ASzzLBSSMj_h4vGP path{fill:var(--figma-color-bg)}.FiJRc0JKlV69I2_ySvpM{width:100%;color:var(--figma-color-text);background:var(--figma-color-bg);border-radius:12px;box-shadow:0px 3px 7px rgba(0,0,0,.1),0px 6px 14px rgba(0,0,0,.1),0px 9px 28px rgba(0,0,0,.05)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/toolbar/export/render/router.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,yBAAA,CAGF,sBACE,gBAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,0BAAA,CACA,WAAA,CACA,yBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,SAAA,CACA,6CAAA,CAEA,2BACE,0BC5BW,CDgCf,sBACE,UAAA,CACA,6BCoGe,CDnGf,gCCnCa,CDoCb,kBAAA,CACA,8FACE","sourceRoot":""}]);
// Exports
var _1 = `NxZka5XlbvsRS4ZhOSvp`;
export { _1 as "popover-container" };
var _2 = `sbt4o1vWZp49C7EP7lCI`;
export { _2 as "popover-content" };
export var container = `lG_fqCv47ZycwKL19KCY`;
export var arrow = `Xe34ASzzLBSSMj_h4vGP`;
export var content = `FiJRc0JKlV69I2_ySvpM`;
export default ___CSS_LOADER_EXPORT___;
