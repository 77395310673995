import {
  ColorStopsRelationsAspect,
  FillsRelationsAspect,
  HashComponent,
  MaskComponent,
  PaintType,
  PaintTypeComponent,
  RgbaValueComponent,
  StrokesRelationsAspect,
  getAnimatableValue,
} from '@aninix-inc/model'
import { PropertyRowV2 } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getFlattenChildren } from '../../../utils/getFlattenChildren'
import { Paint } from '../paint'

/**
 * @todo add color grouping
 */
export const ColorSelection: React.FC = () => {
  const { time, entitiesWithPaints: nodes } = useNodePropertiesPanel()

  const flattenNodes = getFlattenChildren(nodes)

  const filteredNodes = flattenNodes.filter(
    (n) =>
      n.hasAspect(FillsRelationsAspect) || n.hasAspect(StrokesRelationsAspect)
  )

  useEntities(filteredNodes)

  if (!filteredNodes.length) return null

  const paints = filteredNodes
    .filter((n) => !n.getComponent(MaskComponent)?.value)
    .flatMap((n) =>
      [
        n.getAspectOrThrow(FillsRelationsAspect).getChildrenList(),
        n.getAspectOrThrow(StrokesRelationsAspect).getChildrenList(),
      ].flat()
    )

  const groupedPaints = R.collectBy((paint) => {
    const type = paint.getComponentOrThrow(PaintTypeComponent).value

    switch (type) {
      case PaintType.Solid: {
        // @TODO: use getRgba or getColor function
        const value = getAnimatableValue(
          paint.getComponentOrThrow(RgbaValueComponent),
          time
        )
        return `${type}-${JSON.stringify(value)}`
      }

      case PaintType.GradientLinear:
      case PaintType.GradientRadial:
        return `${type}-${paint
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()
          .map((colorStop) => {
            const value = getAnimatableValue(
              colorStop.getComponentOrThrow(RgbaValueComponent),
              time
            )
            return JSON.stringify(value)
          })
          .join('-')}`

      case PaintType.Image:
        return `${type}-${paint.getComponentOrThrow(HashComponent).value}`

      default: {
        const never: never = type
        return never
      }
    }
  }, paints)

  if (flattenNodes.length <= 1 || paints.length === 0) return null

  return (
    <PropertyRowV2
      name="Selection colors"
      inputs={
        paints.length === 0 ? undefined : (
          <div className="flex w-full flex-col gap-[8px]">
            {groupedPaints.map((paints) => (
              <Paint key={paints[0].id} paints={paints} />
            ))}
          </div>
        )
      }
      empty={paints.length === 0}
    />
  )
}

ColorSelection.displayName = 'ColorSelection'
