import {
  Entity,
  NumberKeyframe,
  StrokeAlign,
  StrokeAlignComponent,
  StrokeWeightComponent,
  getValueNumber,
  isIndividualCornerRadiusAvailable,
  isIndividualStrokesAvailable,
  isIndividualStrokesEnabled,
  mixed,
  round,
  toggleIndividualStrokes,
} from '@aninix-inc/model'
import { Select, buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const smallIconSize = {
  x: 12,
  y: 12,
}

const iconSize = {
  x: 32,
  y: 32,
}

const btnSize = {
  width: 32,
  height: 32,
}

const strokeAlignOptions = [
  {
    id: StrokeAlign.Center,
    title: 'Center',
  },
  {
    id: StrokeAlign.Inside,
    title: 'Inside',
  },
  {
    id: StrokeAlign.Outside,
    title: 'Outside',
  },
]

export const StrokeWeight: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time, snapshots } = useNodePropertiesPanel()
  useEntities(nodes)
  const strokeWeightComponents = nodes.map((n) =>
    n.getComponentOrThrow(StrokeWeightComponent)
  )
  const strokeAlignComponents = nodes.map((n) =>
    n.getComponentOrThrow(StrokeAlignComponent)
  )
  const strokeAlign = R.all(
    (c) => c.value === strokeAlignComponents[0].value,
    strokeAlignComponents
  )
    ? strokeAlignComponents[0].value
    : mixed

  const individualStrokesEnabled = R.all(
    (n) =>
      isIndividualCornerRadiusAvailable(n) && isIndividualStrokesEnabled(n),
    nodes
  )

  const individualStrokesAvailable = isIndividualStrokesAvailable(nodes[0])

  const weights = snapshots.map((s) => s.strokeWeight)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <StrokeWeightEditable
          time={time}
          strokeWeightComponents={strokeWeightComponents}
          strokeAlign={strokeAlign}
          individualStrokesEnabled={individualStrokesEnabled}
          individualStrokesAvailable={individualStrokesAvailable}
          nodes={nodes}
        />
      ) : (
        <StrokeWeightDisplay
          time={time}
          strokeWeightComponents={strokeWeightComponents}
          strokeAlign={strokeAlign}
          individualStrokesEnabled={individualStrokesEnabled}
          individualStrokesAvailable={individualStrokesAvailable}
          weights={weights}
        />
      )}
    </div>
  )
}

StrokeWeight.displayName = 'StrokeWeight'

const StrokeWeightEditable: React.FC<{
  time: number
  strokeWeightComponents: StrokeWeightComponent[]
  strokeAlign: StrokeAlign | 'Mixed'
  individualStrokesEnabled: boolean
  individualStrokesAvailable: boolean
  nodes: Entity[]
}> = ({
  time,
  strokeWeightComponents,
  strokeAlign,
  individualStrokesEnabled,
  individualStrokesAvailable,
  nodes,
}) => {
  return (
    <div className="flex w-full flex-row justify-between pl-[6px]">
      <Select
        activeValueId={strokeAlign}
        options={strokeAlignOptions}
        onChange={(newStrokeAlign) =>
          nodes.forEach((n) =>
            n.updateComponent(
              StrokeAlignComponent,
              newStrokeAlign as StrokeAlign
            )
          )
        }
        className="w-[96px]"
      />

      <NumberValue
        components={strokeWeightComponents}
        time={time}
        icon={<icons.StrokeWeight size={iconSize} />}
        min={0}
        width={96}
        disabled={individualStrokesEnabled}
        dragDisabled={individualStrokesEnabled}
        format={(v) => `${round(v, { fixed: 2 })}`}
      />

      {individualStrokesAvailable && (
        <buttons.Icon
          onClick={() => {
            nodes.forEach((n) => toggleIndividualStrokes(n))
          }}
          btnSize={btnSize}
          active={individualStrokesEnabled}
          tooltip="Strokes per side"
        >
          <icons.propertiesPanel.IndividualStrokes size={smallIconSize} />
        </buttons.Icon>
      )}

      <KeyframesPropertyControl
        components={strokeWeightComponents}
        isDisabled={individualStrokesEnabled}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  )
}

StrokeWeightEditable.displayName = 'StrokeWeightEditable'

interface StrokeWeightDisplayProps {
  time: number
  strokeWeightComponents: StrokeWeightComponent[]
  strokeAlign: StrokeAlign | 'Mixed'
  individualStrokesEnabled: boolean
  individualStrokesAvailable: boolean
  weights: number[]
}

const propsAreEqual = (
  prev: StrokeWeightDisplayProps,
  next: StrokeWeightDisplayProps
) => {
  if (prev.individualStrokesEnabled !== next.individualStrokesEnabled)
    return false
  if (prev.individualStrokesAvailable !== next.individualStrokesAvailable)
    return false
  if (prev.strokeAlign !== next.strokeAlign) return false
  if (prev.weights.length !== next.weights.length) return false
  for (let i = 0; i < prev.weights.length; i++) {
    if (prev.weights[i] !== next.weights[i]) return false
  }

  return true
}

const StrokeWeightDisplay: React.FC<StrokeWeightDisplayProps> = React.memo(
  ({
    time,
    strokeWeightComponents,
    strokeAlign,
    individualStrokesEnabled,
    individualStrokesAvailable,
  }) => {
    return (
      <div className="flex w-full flex-row justify-between pl-[6px]">
        <Select
          activeValueId={strokeAlign}
          options={strokeAlignOptions}
          onChange={() => {}}
          className="w-[96px]"
        />

        <NumberValue
          components={strokeWeightComponents}
          time={time}
          icon={<icons.StrokeWeight size={iconSize} />}
          min={0}
          width={96}
          disabled={individualStrokesEnabled}
          dragDisabled={individualStrokesEnabled}
          format={(v) => `${round(v, { fixed: 2 })}`}
        />

        {individualStrokesAvailable && (
          <buttons.Icon
            onClick={() => {}}
            btnSize={btnSize}
            active={individualStrokesEnabled}
            tooltip="Strokes per side"
          >
            <icons.propertiesPanel.IndividualStrokes size={smallIconSize} />
          </buttons.Icon>
        )}

        <KeyframesPropertyControl
          components={strokeWeightComponents}
          isDisabled={individualStrokesEnabled}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>
    )
  },
  propsAreEqual
)

StrokeWeightDisplay.displayName = 'StrokeWeightDisplay'
