// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lfuaKUyUtWqDCKBprbrC{display:flex;flex-direction:column;padding:8px 0 8px 0;background-color:#0b1118}.vbjWhSHzphev3meUCQd6{display:flex;align-items:center;padding:8px;background-color:#0b1118;border:none}.vbjWhSHzphev3meUCQd6:hover{background-color:var(--figma-color-bg-brand)}.mncp3wEn9w7J7aVzabHS{background-color:var(--figma-color-bg-brand) !important}.dUkNz3fg6MAGEhaOvWRI{display:flex;flex-direction:column;align-items:center;justify-content:center;width:16px;height:16px;margin:0;padding:0;border:none;outline:none}.dUkNz3fg6MAGEhaOvWRI svg{position:relative;top:0;left:0;width:100%}.vCUGOUFcGiwdCIYKoW3y{display:block;width:calc(100% - 6px);height:calc(100% - 6px);background-color:orange;border-radius:2px}.fouvs9ORZnfr5C8Naj4c{font-weight:500;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;margin-left:8px;padding:0;color:#fff}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/ColorSelector/styles.scss","webpack://./packages/app-design-system/src/theme/colors.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CAEA,mBAAA,CACA,wBCuCe,CDpCjB,sBACE,YAAA,CACA,kBAAA,CAEA,WAAA,CACA,wBC+Be,CD9Bf,WAAA,CAEA,4BACE,4CEhBgB,CFmBlB,sBACE,uDAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,UAAA,CACA,WAAA,CACA,QAAA,CAEA,SAAA,CACA,WAAA,CAEA,YAAA,CAEA,0BACE,iBAAA,CACA,KAAA,CACA,MAAA,CAEA,UAAA,CAIJ,sBACE,aAAA,CACA,sBAAA,CACA,uBAAA,CACA,uBAAA,CACA,iBAAA,CAGF,sBGTE,eAAA,CACA,cAAA,CACA,8BCpDa,CDqDb,gBAAA,CACA,qBAAA,CHOA,QAAA,CACA,eAAA,CACA,SAAA,CACA,UCrCiB","sourceRoot":""}]);
// Exports
export var container = `lfuaKUyUtWqDCKBprbrC`;
export var item = `vbjWhSHzphev3meUCQd6`;
var _1 = `mncp3wEn9w7J7aVzabHS`;
export { _1 as "item--selected" };
var _2 = `dUkNz3fg6MAGEhaOvWRI`;
export { _2 as "color-button" };
export var color = `vCUGOUFcGiwdCIYKoW3y`;
var _3 = `fouvs9ORZnfr5C8Naj4c`;
export { _3 as "color-label" };
export default ___CSS_LOADER_EXPORT___;
