// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XI6ofbjttmUm68BEN9l9{position:absolute;top:50%;left:0;display:flex;flex-direction:column;align-items:center;justify-content:center;height:14px;margin:0;background:none;border:none;outline:none;transform:translate(-6px, -50%);cursor:default}.XI6ofbjttmUm68BEN9l9:hover{opacity:.85}.VQBQmm0wJqHsDXLgqwJC{position:absolute;top:50%;left:0;display:block;width:calc(100% - 12px);height:2px;transform:translate(6px, -50%)}.aKnnrxebBwFyny2DrkC1{position:absolute;top:50%;left:6px;display:block;width:calc(100% - 12px);height:100%;transform:translateY(-50%)}.UjChy0fKbamMUJmvxI9t{--color: 255, 255, 255;font-weight:600;font-size:10px;font-family:"Inter",sans-serif;line-height:14px;letter-spacing:.005em;position:absolute;top:50%;left:50%;width:fit-content;min-width:20px;padding:1px 4px;overflow:hidden;color:#fff;white-space:nowrap;text-overflow:ellipsis;background-color:rgb(var(--color));border-radius:100px;transform:translate(-50%, -50%)}.bla51Z5DwKyY_pTEr2X3{color:rgb(var(--color));background-color:rgba(var(--color), 0.4)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/property/segment/index.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAEA,OAAA,CACA,MAAA,CAEA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,QAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CAEA,+BAAA,CACA,cAAA,CAEA,4BACE,WAAA,CAIJ,sBACE,iBAAA,CACA,OAAA,CACA,MAAA,CACA,aAAA,CACA,uBAAA,CACA,UAAA,CACA,8BAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CAGA,aAAA,CACA,uBAAA,CAEA,WAAA,CACA,0BAAA,CAGF,sBACE,sBAAA,CCuCA,eAAA,CACA,cAAA,CACA,8BC3Fa,CD4Fb,gBAAA,CACA,qBAAA,CDxCA,iBAAA,CAEA,OAAA,CACA,QAAA,CAEA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,UGnCiB,CHoCjB,kBAAA,CACA,sBAAA,CAEA,kCAAA,CACA,mBAAA,CACA,+BAAA,CAEA,sBACE,uBAAA,CACA,wCAAA","sourceRoot":""}]);
// Exports
export var container = `XI6ofbjttmUm68BEN9l9`;
export var regular = `VQBQmm0wJqHsDXLgqwJC`;
export var selected = `aKnnrxebBwFyny2DrkC1`;
var _1 = `UjChy0fKbamMUJmvxI9t`;
export { _1 as "curve-style-name" };
var _2 = `bla51Z5DwKyY_pTEr2X3`;
export { _2 as "curve-style-name--selected" };
export default ___CSS_LOADER_EXPORT___;
