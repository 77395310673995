import { Project, Snapshot } from '@aninix-inc/model'

import { FullPageLoader } from '@aninix/app-design-system'

import * as React from 'react'
import { createEmptyProject } from '../inspector/components/overrides-export/utils/create-empty-project'
import { ProjectPreview } from './project-preview'
import { AutostartExportOption, VideoGifScaleOption } from './render-handler'

export type IncomingQuickExportEvents = ProjectEvent | ExportEvent | PlayEvent

export type ProjectEvent = {
  type: 'project'
  value: { snapshot: Snapshot; images?: Record<string, Uint8Array> }
}

export type ExportEvent = {
  type: 'export'
  value: {
    format: AutostartExportOption
    exportSessionId: string
    scale?: VideoGifScaleOption
  }
}

export type PlayEvent = {
  type: 'play'
}

export type OutgoingQuickExportEvents =
  | ExportStarted
  | Progress
  | ExportFinished
  | ReadyForProject
  | ErrorHappend

export type ReadyForProject = {
  type: 'ready-for-project'
}

export type ExportStarted = {
  type: 'export-started'
}

export type Progress = {
  type: 'progress'
  value: number
}

export type ExportFinished = {
  type: 'export-finished'
}

export type ErrorHappend = {
  type: 'error-happend'
  value: string
}

export interface IProps {}

export const QuickExport: React.FCC<IProps> = () => {
  const [project, setProject] = React.useState<Project | undefined>()

  const [autostartExportOption, setAutostartExportOption] = React.useState<
    AutostartExportOption | undefined
  >()

  const [defaultScale, setDefaultScale] =
    React.useState<VideoGifScaleOption>('1×')

  const [images, setImages] = React.useState<Record<string, Uint8Array>>()

  const onmessage = (event: MessageEvent<any>) => {
    const typedEvent = event.data as IncomingQuickExportEvents

    switch (typedEvent.type) {
      case 'project': {
        setProject(
          createEmptyProject(typedEvent.value.snapshot.id).applySnapshot(
            typedEvent.value.snapshot
          )
        )

        setImages(typedEvent.value.images)

        return
      }
      case 'export': {
        if (typedEvent.value.scale != null) {
          setDefaultScale(typedEvent.value.scale)
        }

        setAutostartExportOption(typedEvent.value.format)
        setTimeout(() => {
          //this will trigger autoexport and return to initial state
          setAutostartExportOption(undefined)
        }, 1)
        return
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', onmessage)

    window.parent.postMessage(
      { type: 'ready-for-project' } satisfies ReadyForProject,
      '*'
    )

    return () => {
      window.removeEventListener('message', onmessage)
    }
  }, [])

  if (project == null) return <FullPageLoader />

  return (
    <ProjectPreview
      autostartExportOption={autostartExportOption}
      defaultScale={defaultScale}
      project={project}
      images={images}
    />
  )
}

QuickExport.displayName = 'QuickExport'
