// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hh8HlDN7aFgjRrXfSf6m{position:relative;height:100%;overflow-x:hidden;overflow-y:auto;scroll-behavior:auto;-ms-overflow-style:none;scrollbar-width:none}.hh8HlDN7aFgjRrXfSf6m::-webkit-scrollbar{display:none}.vV8cpkP18JDWuVICsfen .aA0RDVPCXTRQIp2A5Cvu{display:block}.aA0RDVPCXTRQIp2A5Cvu{--height: 0px;--top: 0px;--scroll-offset: 0px;position:absolute;top:0;right:0;z-index:1001;display:none;width:6px;height:100%;background:rgba(0,0,0,0);transform:translateY(var(--scroll-offset))}.aA0RDVPCXTRQIp2A5Cvu::after{position:absolute;top:0;right:0;width:6px;height:var(--height);background:var(--aninix-color-foreground-black-2);border-radius:9999px;transform:translateY(var(--top));content:""}`, "",{"version":3,"sources":["webpack://./packages/editor/src/components/scrollable/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CACA,oBAAA,CAEA,uBAAA,CACA,oBAAA,CAGA,yCACE,YAAA,CAIA,4CACE,aAAA,CAKN,sBACE,aAAA,CACA,UAAA,CACA,oBAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,YAAA,CACA,YAAA,CACA,SAAA,CACA,WAAA,CACA,wBAAA,CACA,0CAAA,CAEA,6BACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,SAAA,CACA,oBAAA,CACA,iDCvCkB,CDwClB,oBAAA,CACA,gCAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var container = `hh8HlDN7aFgjRrXfSf6m`;
var _1 = `vV8cpkP18JDWuVICsfen`;
export { _1 as "container--hovered" };
export var scrollbar = `aA0RDVPCXTRQIp2A5Cvu`;
export default ___CSS_LOADER_EXPORT___;
