// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l6_R1dvhrJusFilg_YJz{width:100%;padding:0;background-color:var(--figma-color-bg);border-radius:4px;opacity:.4}.l6_R1dvhrJusFilg_YJz:hover{opacity:1}.dFTEzhr3BSNNh48V4grv{padding:8px 0;background-color:var(--figma-color-bg-hover);opacity:1}.hjFbuyK9df8ueGLgNrmR{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:space-between;padding:0 0 0 8px}.VjF9itzMZBbDRYmGISGl{padding:0 4px}.AFWjFEPwvVrI5epaWPqz{padding:0 4px 0 8px}.q_sLbGMwYeA10BB30LPa{display:flex;flex-direction:row;flex-shrink:0;flex-wrap:nowrap;align-items:stretch;justify-content:flex-end}.l5q3oHXdypKglRJioZaM{font-weight:500;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0}.Xq2cr_bnKXWqxKUR8lMe{padding:0 4px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/properties-panel/components/common/group/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,SAAA,CACA,sCCHa,CDIb,iBAAA,CACA,UAAA,CAEA,4BACE,SAAA,CAGF,sBACE,aAAA,CACA,4CCIgB,CDHhB,SAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,6BAAA,CACA,iBAAA,CAEA,sBACE,aAAA,CAGF,sBACE,mBAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,aAAA,CACA,gBAAA,CACA,mBAAA,CACA,wBAAA,CAGF,sBEIE,eAAA,CACA,cAAA,CACA,8BCpDa,CDqDb,gBAAA,CACA,qBAAA,CFNA,QAAA,CAGF,sBACE,aAAA","sourceRoot":""}]);
// Exports
export var container = `l6_R1dvhrJusFilg_YJz`;
var _1 = `dFTEzhr3BSNNh48V4grv`;
export { _1 as "container--active" };
export var header = `hjFbuyK9df8ueGLgNrmR`;
var _2 = `VjF9itzMZBbDRYmGISGl`;
export { _2 as "header--shrinked" };
var _3 = `AFWjFEPwvVrI5epaWPqz`;
export { _3 as "header--expanded" };
var _4 = `q_sLbGMwYeA10BB30LPa`;
export { _4 as "header-buttons" };
export var title = `l5q3oHXdypKglRJioZaM`;
export var content = `Xq2cr_bnKXWqxKUR8lMe`;
export default ___CSS_LOADER_EXPORT___;
