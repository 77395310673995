// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NDa5r_YWazwWPe5cO8kQ{display:flex;flex-direction:column;align-items:stretch;justify-content:flex-start;width:100vw;height:100vh;color:var(--figma-color-text);background-color:var(--figma-color-bg)}.qAoqrqgUbaRPYM34AP9c{height:48px;background-color:#2c2c2c}.CNLnYmwmvca3ju5ljOJd{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start;height:calc(100% - 48px)}.ZipLIKaT0Eju2QUaJJCu{display:flex;flex:1;flex-direction:column;align-items:stretch;justify-content:flex-start;width:calc(100% - 240px)}.j2n3RW_k1OPJrIWVS76y{flex:2;background-color:var(--figma-color-icon-tertiary);background-size:400% 400%}.a5MWlM6WMu8JuCU0Wqp0{background-color:var(--aninix-color-foreground-black-05);background-size:400% 400%;border-top:1px solid var(--aninix-color-foreground-black-05)}.u0c6DybjKCIEoaAZQkeN{width:240px;background-color:var(--aninix-color-foreground-black-05);background-size:400% 400%;border-left:1px solid var(--aninix-color-foreground-black-05)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/components/project-editor-skeleton/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/colors.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss"],"names":[],"mappings":"AAQA,sBACE,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,0BAAA,CAEA,WAAA,CACA,YAAA,CACA,6BCwHe,CDvHf,sCCfa,CDkBf,sBACE,WAAA,CACA,wBEyBmB,CFtBrB,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CAEA,wBAAA,CAGF,sBACE,YAAA,CACA,MAAA,CACA,qBAAA,CACA,mBAAA,CACA,0BAAA,CAEA,wBAAA,CAGF,sBACE,MAAA,CACA,iDCmFuB,CDlFvB,yBAAA,CAGF,sBACE,wDGjDqB,CHkDrB,yBAAA,CACA,4DAAA,CAGF,sBACE,WAvDuB,CAwDvB,wDGxDqB,CHyDrB,yBAAA,CACA,6DAAA","sourceRoot":""}]);
// Exports
export var container = `NDa5r_YWazwWPe5cO8kQ`;
export var toolbar = `qAoqrqgUbaRPYM34AP9c`;
export var layout = `CNLnYmwmvca3ju5ljOJd`;
var _1 = `ZipLIKaT0Eju2QUaJJCu`;
export { _1 as "working-area" };
export var viewport = `j2n3RW_k1OPJrIWVS76y`;
export var timeline = `a5MWlM6WMu8JuCU0Wqp0`;
var _2 = `u0c6DybjKCIEoaAZQkeN`;
export { _2 as "properties-panel" };
export default ___CSS_LOADER_EXPORT___;
