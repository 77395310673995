// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Af_K2MGiEx_1B8eob1Iq{display:flex;flex-direction:column;align-items:stretch;justify-content:flex-start;width:100vw;height:100vh;color:var(--figma-color-text);background-color:var(--figma-color-bg)}.ri4x6pJZpg0rs9Icc_cf{z-index:10;display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start;height:calc(100% - 48px)}.Ks28pezbSso8oYYCrFA5{display:flex;flex:1;flex-direction:column;align-items:stretch;justify-content:flex-start;width:calc(100% - 240px)}.dDJMlK9wc74yhodTpocW{position:relative;flex:2}.qTVULTNpva6I3RkA6maL{width:240px;border-left:1px solid var(--aninix-color-foreground-black-05)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/project-editor/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAQA,sBACE,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,0BAAA,CAEA,WAAA,CACA,YAAA,CACA,6BCwHe,CDvHf,sCCfa,CDkBf,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CAEA,wBAAA,CAGF,sBACE,YAAA,CACA,MAAA,CACA,qBAAA,CACA,mBAAA,CACA,0BAAA,CAEA,wBAAA,CAGF,sBACE,iBAAA,CACA,MAAA,CAGF,sBACE,WA5CuB,CA6CvB,6DAAA","sourceRoot":""}]);
// Exports
export var container = `Af_K2MGiEx_1B8eob1Iq`;
export var layout = `ri4x6pJZpg0rs9Icc_cf`;
var _1 = `Ks28pezbSso8oYYCrFA5`;
export { _1 as "working-area-wrapper" };
var _2 = `dDJMlK9wc74yhodTpocW`;
export { _2 as "viewport-wrapper" };
var _3 = `qTVULTNpva6I3RkA6maL`;
export { _3 as "properties-panel-wrapper" };
export default ___CSS_LOADER_EXPORT___;
