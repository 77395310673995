// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pTaM4g_uZzoXmGuzyqAP{position:relative;display:block;user-select:none}.pTaM4g_uZzoXmGuzyqAP input{position:absolute;opacity:0}.j8N8HhF3xH6gjyzE7EEc{position:absolute;top:0;left:0;width:9px;height:9px;background-color:rgba(0,0,0,0);border:1px solid var(--figma-color-text);border-radius:50%}.pTaM4g_uZzoXmGuzyqAP:hover input~.j8N8HhF3xH6gjyzE7EEc{background-color:var(--aninix-color-foreground-black-1)}.pTaM4g_uZzoXmGuzyqAP input:checked~.j8N8HhF3xH6gjyzE7EEc{background-color:var(--figma-color-bg-brand);border:1px solid var(--figma-color-bg-brand)}.j8N8HhF3xH6gjyzE7EEc:after{position:absolute;display:none;content:""}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/anchor-point/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,aAAA,CACA,gBAAA,CAGF,4BACE,iBAAA,CACA,SAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,SAAA,CACA,UAAA,CACA,8BAAA,CACA,wCAAA,CACA,iBAAA,CAGF,wDACE,uDCrBoB,CDwBtB,0DACE,4CE1BkB,CF2BlB,4CAAA,CAGF,4BACE,iBAAA,CACA,YAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var container = `pTaM4g_uZzoXmGuzyqAP`;
var _1 = `j8N8HhF3xH6gjyzE7EEc`;
export { _1 as "check-mark" };
export default ___CSS_LOADER_EXPORT___;
