// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AmfybCiQUincqCtdVCGw{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;justify-content:space-between}.cFVwTvIdckLlHV1s80OA{background-color:var(--aninix-color-foreground-black-05)}.YVR7NegqYoHyuU3prp0A{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start}.Y0hQeLRZpTicV4zMHxKn{width:32px;height:32px}.CSua3cecznT8vVI4JRGv{color:var(--figma-color-text);background:var(--figma-color-bg)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/paint-control/styles.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,6BAAA,CAEA,sBACE,wDCPmB,CDWvB,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CAGF,sBACE,UAAA,CACA,WAAA,CAGF,sBACE,6BE4Ge,CF3Gf,gCE3Ba","sourceRoot":""}]);
// Exports
export var container = `AmfybCiQUincqCtdVCGw`;
var _1 = `cFVwTvIdckLlHV1s80OA`;
export { _1 as "container--expanded" };
export var buttons = `YVR7NegqYoHyuU3prp0A`;
export var spacer = `Y0hQeLRZpTicV4zMHxKn`;
export var wrapper = `CSua3cecznT8vVI4JRGv`;
export default ___CSS_LOADER_EXPORT___;
