import {
  CharactersComponent,
  EntityType,
  EntityTypeComponent,
  NameComponent,
  NodeType,
  NodeTypeComponent,
  StyledTextSegmentRelationsAspect,
} from '@aninix-inc/model'
import * as React from 'react'
import { ComponentGroupPanel } from '../../components/component-group-panel'
import { useOverride } from '../use-override-item'
import { TextItem } from './texts/text-item'
import {
  convertEntityToSnapshot,
  createTextStyle,
  extractSegmentMeta,
} from '@aninix-inc/renderer'
import { useImagesStore, useProject } from '@aninix/core'
import { renderToStaticMarkup } from 'react-dom/server'
import tinycolor from 'tinycolor2'

function trimmed(string: string, maxCharacters: number): string {
  if (string.length > maxCharacters) {
    return string.substring(0, maxCharacters) + '...'
  }

  return string
}

export interface IProps {}
export const Texts: React.FCC<IProps> = () => {
  const {
    openedPaneles,
    updateOpenedPanels,
    data: { project },
  } = useOverride()

  const playbackProject = useProject()

  const data = project
    .getEntitiesByPredicate(
      (e) =>
        e.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node &&
        e.getComponentOrThrow(NodeTypeComponent).value === NodeType.Text
    )
    .map((entity) => {
      return {
        type: 'text' as const,
        id: entity.id,
        name: trimmed(entity.getComponentOrThrow(NameComponent).value, 14),
        value: entity
          .getAspectOrThrow(StyledTextSegmentRelationsAspect)
          .getChildrenList()
          .map((child, idx) => {
            const meta = extractSegmentMeta(child)
            return renderToStaticMarkup(
              <span
                className="inner-markup"
                id={child.id}
                style={{
                  fontStyle: meta.fontStyle,
                  fontWeight: meta.fontWeight,
                  color: meta.color
                    ? `rgba(${meta.color.r}, ${meta.color.g}, ${meta.color.b}, ${meta.color.a})`
                    : undefined,
                }}
              >
                {child.getComponentOrThrow(CharactersComponent).value}
              </span>
            )
          })
          .join(''),
      }
    })

  React.useEffect(() => {
    const handleCopy = (e: ClipboardEvent) => {
      const target = e.target as HTMLElement
      if (!target.closest('.inner-markup')) {
        return
      }

      if (
        //if user selected 1 node, we need to make sure it has style due to copy pasting specifics
        //@ts-ignore
        window.getSelection()?.anchorNode !== window.getSelection()?.extentNode
      ) {
        return
      }

      e.preventDefault()
      const findSegment = playbackProject.getEntityOrThrow(target.id)
      const meta = extractSegmentMeta(findSegment)

      const text = target.innerText
      const html = `<span id="${target.id}" class="inner-markup" style="font-Style: ${meta.fontStyle}; font-weight: ${meta.fontWeight};${
        meta.color
          ? `color: rgba(${meta.color.r}, ${meta.color.g}, ${meta.color.b}, ${meta.color.a})`
          : ''
      }">${window.getSelection()?.toString()}</span>`
      e.clipboardData?.setData('text/html', html)
      e.clipboardData?.setData('text/plain', text)
    }

    document.addEventListener('copy', handleCopy)

    return () => {
      document.removeEventListener('copy', handleCopy)
    }
  }, [])

  if (data === undefined || data.length === 0) {
    return null
  }

  return (
    <div>
      <ComponentGroupPanel
        isOpen={openedPaneles.texts}
        toggleOpen={() => updateOpenedPanels({ texts: !openedPaneles.texts })}
        label="Texts"
      >
        <div className="flex flex-col gap-1">
          {data.map((item) => (
            <TextItem key={item.id} data={item} />
          ))}
        </div>
      </ComponentGroupPanel>
    </div>
  )
}

Texts.displayName = 'Texts'
