// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ATo27itWaBNiSZ9RvV0H{font-weight:700;font-size:8px;font-family:"Inter",sans-serif;line-height:13px;letter-spacing:.005em;width:fit-content;margin:0;padding:0 2px;color:#fff;background-color:var(--figma-color-bg-success);border-radius:2px}.v6EHo0yOeO4_RGTamxgd{background-color:#b3b3b3}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/pro-badge/index.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/colors.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBC+EE,eAAA,CACA,aAAA,CACA,8BCnFa,CDoFb,gBAAA,CACA,qBAAA,CDjFA,iBAAA,CACA,QAAA,CACA,aAAA,CACA,UGoBiB,CHnBjB,8CIwBoB,CJvBpB,iBAAA,CAEA,sBACE,wBG2BiB","sourceRoot":""}]);
// Exports
export var container = `ATo27itWaBNiSZ9RvV0H`;
var _1 = `v6EHo0yOeO4_RGTamxgd`;
export { _1 as "container--dimmed" };
export default ___CSS_LOADER_EXPORT___;
