// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o29J6ld15ARWssLCrQK7{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:flex-start;padding:16px 12px;border-bottom:1px solid var(--aninix-color-foreground-black-05)}.zi09rAFBaT7lb3PmqcZj{flex-shrink:0;width:10px;height:10px;border-radius:2px}.ZQM0cUzQHH1xXvGXOJAW{font-weight:600;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;margin-left:8px;padding:0}.W5lcRUfS0rrpBNlr17GC{margin:0 8px;color:var(--figma-color-text-disabled)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/PropertyLabel/styles.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,0BAAA,CAEA,iBAAA,CACA,+DAAA,CAGF,sBACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,sBC6EE,eAAA,CACA,cAAA,CACA,8BCnGa,CDoGb,gBAAA,CACA,qBAAA,CD/EA,QAAA,CACA,eAAA,CACA,SAAA,CAGF,sBACE,YAAA,CACA,sCGsHuB","sourceRoot":""}]);
// Exports
export var container = `o29J6ld15ARWssLCrQK7`;
export var color = `zi09rAFBaT7lb3PmqcZj`;
export var name = `ZQM0cUzQHH1xXvGXOJAW`;
export var separator = `W5lcRUfS0rrpBNlr17GC`;
export default ___CSS_LOADER_EXPORT___;
