// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kX4yFaJ2VMTfhNQeEF4L{height:18px;padding:1px 8px;padding-left:10px;color:var(--figma-color-text);background-color:var(--figma-color-bg);border:1px solid rgba(0,0,0,0);border-radius:2px;outline:none;cursor:default}.kX4yFaJ2VMTfhNQeEF4L:hover{border-color:var(--aninix-color-foreground-black-1) !important}.kX4yFaJ2VMTfhNQeEF4L:focus{padding:0 7px;padding-left:9px;border-color:var(--figma-color-bg-brand) !important;border-width:2px}.TUepDNYKg8HfYdYT6aaT{height:100% !important}.ghnhOgQZFl6qSj3QoC7E{width:100%}.tdfax2kGxAXz8QSoL8Pq{border-color:var(--aninix-color-foreground-black-05) !important}.UVcGg4Qp3BzHsQmEz8Ee{font-weight:400;font-size:10px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}.LoJsmrd20uNCm1YOfCHw{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/input-timeline/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,eAAA,CACA,iBAAA,CACA,6BCkIe,CDjIf,sCCLa,CDMb,8BAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CAEA,4BACE,8DAAA,CAGF,4BACE,aAAA,CACA,gBAAA,CACA,mDAAA,CACA,gBAAA,CAGF,sBACE,sBAAA,CAIJ,sBACE,UAAA,CAGF,sBACE,+DAAA,CAGF,sBElCE,eAAA,CACA,cAAA,CACA,8BCLa,CDMb,gBAAA,CACA,qBAAA,CFkCF,sBE9BE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
export var input = `kX4yFaJ2VMTfhNQeEF4L`;
var _1 = `TUepDNYKg8HfYdYT6aaT`;
export { _1 as "input--full-height" };
export var width = `ghnhOgQZFl6qSj3QoC7E`;
export var border = `tdfax2kGxAXz8QSoL8Pq`;
export var small = `UVcGg4Qp3BzHsQmEz8Ee`;
export var medium = `LoJsmrd20uNCm1YOfCHw`;
export default ___CSS_LOADER_EXPORT___;
