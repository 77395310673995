// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wbj8x5BwyOYNqKPEt4xh{background-color:#0b1118}.qvAcQUU2slNXaXsIJiXZ{color:#0b1118}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/tooltip/index.scss","webpack://./packages/app-design-system/src/theme/colors.scss"],"names":[],"mappings":"AAEA,sBACE,wBC2Ce,CDxCjB,sBACE,aCuCe","sourceRoot":""}]);
// Exports
export var container = `Wbj8x5BwyOYNqKPEt4xh`;
export var arrow = `qvAcQUU2slNXaXsIJiXZ`;
export default ___CSS_LOADER_EXPORT___;
