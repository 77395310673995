// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JNuT1R06Rvd_C4j4ndhw{padding:12px 16px 16px 16px}.nAzeHorTaX2TmcEsImos{font-weight:600;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;display:flex;flex-direction:column;align-items:flex-start;justify-content:center;width:100%;height:30px;margin:0}.kPjdd2qzWkBQpaeCK2FE{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;margin-top:4px;color:var(--figma-color-icon-onselected-secondary)}.kPjdd2qzWkBQpaeCK2FE a{color:var(--figma-color-bg-brand)}.Aww1_l5kcavKfcnaFJdS{margin-top:12px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/toolbar/export/render/success.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,2BAAA,CAGF,sBC2FE,eAAA,CACA,cAAA,CACA,8BCnGa,CDoGb,gBAAA,CACA,qBAAA,CD7FA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CAGF,sBCNE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CDIA,QAAA,CACA,cAAA,CACA,kDGwFkC,CHtFlC,wBACE,iCGrBgB,CHyBpB,sBACE,eAAA","sourceRoot":""}]);
// Exports
export var container = `JNuT1R06Rvd_C4j4ndhw`;
export var title = `nAzeHorTaX2TmcEsImos`;
export var description = `kPjdd2qzWkBQpaeCK2FE`;
export var buttons = `Aww1_l5kcavKfcnaFJdS`;
export default ___CSS_LOADER_EXPORT___;
