// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pp1YiXJc4cfpXrClwHKq{--color: #18a0fb;--highlight-color: #18a0fb;--highlight-indent: 0px;--mask-color: var(--aninix-color-foreground-black-1);font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;position:relative;z-index:1;height:18px;background-color:var(--color)}.pp1YiXJc4cfpXrClwHKq::before{position:absolute;bottom:0;left:0;width:100%;height:1px;background:var(--mask-color);content:""}._v82V8Ih91NUUZZUp9dR::after{position:absolute;top:0;right:0;bottom:0;left:0;border:1px solid var(--highlight-color);content:"";pointer-events:none}.Zxl23zknXglFX_hgdvd9::after{border-right:none}.pPHpEqDvVYwkNehhRGJH::after{border-left:none}.OeSBwrtPePHijmmcmZ3t::after{left:var(--highlight-indent);border-width:0;border-bottom-width:2px;transition:left .2s}.bRzqyuhbRMMNzW8wb0m_{position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;opacity:.15}.kbCVlKhOWRqfnsCSO0yA{opacity:.05}.Kq3etSTnUIMmaQN98XB9{height:100%}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/timeline-track/styles.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAKA,sBACE,gBAAA,CACA,0BAAA,CACA,uBAAA,CACA,oDAAA,CCEA,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CDHA,iBAAA,CACA,SAAA,CACA,WAAA,CACA,6BAAA,CAEA,8BACE,iBAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CACA,UAAA,CACA,4BAAA,CACA,UAAA,CAUA,6BACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,uCAAA,CACA,UAAA,CACA,mBAAA,CAIA,6BACE,iBAAA,CAKF,6BACE,gBAAA,CAKF,6BACE,4BAAA,CACA,cAAA,CACA,uBAAA,CACA,mBAAA,CAMR,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CAEA,sBACE,WAAA,CAIJ,sBACE,WAAA","sourceRoot":""}]);
// Exports
export var container = `pp1YiXJc4cfpXrClwHKq`;
var _1 = `_v82V8Ih91NUUZZUp9dR`;
export { _1 as "container--highlighted" };
var _2 = `Zxl23zknXglFX_hgdvd9`;
export { _2 as "container--highlighted-left" };
var _3 = `pPHpEqDvVYwkNehhRGJH`;
export { _3 as "container--highlighted-right" };
var _4 = `OeSBwrtPePHijmmcmZ3t`;
export { _4 as "container--highlighted-bottom" };
export var background = `bRzqyuhbRMMNzW8wb0m_`;
var _5 = `kbCVlKhOWRqfnsCSO0yA`;
export { _5 as "background--property" };
export var content = `Kq3etSTnUIMmaQN98XB9`;
export default ___CSS_LOADER_EXPORT___;
