import {
  Point2D,
  Point2dKeyframe,
  ScaleComponent,
  ScaleLockedComponent,
  getValuePoint2d,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { formatPercents } from '../../keyframes/value'
import { LockablePoint2dValue } from '../../values/lockable-point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export const Scale: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time, snapshots } = useNodePropertiesPanel()

  const components = nodes.map((n) => n.getComponentOrThrow(ScaleComponent))
  const lockableComponents = nodes.map((n) =>
    n.getComponentOrThrow(ScaleLockedComponent)
  )

  const keyframeType = getKeyframesType(components, time)

  const scales = snapshots.map((s) => s.scale)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <ScaleEditable
          components={components}
          lockableComponents={lockableComponents}
          time={time}
        />
      ) : (
        <ScaleDisplay
          components={components}
          lockableComponents={lockableComponents}
          time={time}
          scales={scales}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

const ScaleEditable: React.FC<{
  components: ScaleComponent[]
  lockableComponents: ScaleLockedComponent[]
  time: number
}> = ({ components, lockableComponents, time }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <LockablePoint2dValue
            components={components}
            lockableComponents={lockableComponents}
            time={time}
            iconX={<icons.propertiesPanel.ScaleX />}
            iconY={<icons.propertiesPanel.ScaleY />}
            before={(v) => v * 100}
            after={(v) => v / 100}
            formatValue={formatPercents}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={Point2dKeyframe}
          valueGetter={getValuePoint2d}
        />
      }
    />
  )
}

ScaleEditable.displayName = 'ScaleEditable'

interface ScaleDisplayProps {
  components: ScaleComponent[]
  lockableComponents: ScaleLockedComponent[]
  time: number
  scales: Point2D[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (prev: ScaleDisplayProps, next: ScaleDisplayProps) => {
  if (prev.scales.length !== next.scales.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.scales.length; i++) {
    if (
      prev.scales[i].x !== next.scales[i].x ||
      prev.scales[i].y !== next.scales[i].y
    )
      return false
  }
  return true
}

const ScaleDisplay: React.FC<ScaleDisplayProps> = React.memo(
  ({ components, lockableComponents, time }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <LockablePoint2dValue
              components={components}
              lockableComponents={lockableComponents}
              time={time}
              iconX={<icons.propertiesPanel.ScaleX />}
              iconY={<icons.propertiesPanel.ScaleY />}
              before={(v) => v * 100}
              after={(v) => v / 100}
              formatValue={formatPercents}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={Point2dKeyframe}
            valueGetter={getValuePoint2d}
          />
        }
      />
    )
  },
  propsAreEqual
)

ScaleDisplay.displayName = 'ScaleDisplay'
