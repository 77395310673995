// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eFNEoutr7jDBGHsT5qBD{position:absolute;top:2px;z-index:101;display:flex;flex-direction:column;align-items:center;justify-content:stretch;width:16px;height:100%;transform:translateX(-7.5px);pointer-events:none}.Rnn9UP2udb_qbAAGum77{width:15px;height:16px;padding:0;background:none;border:none;outline:none}.KRmAxlyq0eap546MflQc{width:1px;height:100%;background-color:var(--aninix-color-background-silver)}.DsGRjUAd8bzdlN7mUSwP{font-weight:500;font-size:9px;font-family:"Inter",sans-serif;line-height:12px;letter-spacing:.005em;position:absolute;top:0;left:50%;min-width:32px;margin:0;padding:2px 3px;color:var(--figma-color-text);background-color:var(--aninix-color-background-silver);border-radius:4px;transform:translateX(-50%);pointer-events:all}.nb8wSjl4Eb2tSQ_fIS9q{width:100%;margin:0;padding:0;text-align:center}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/time-slider/ghost-slider-view.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAIA,sBACE,iBAAA,CAEA,OAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,uBAAA,CACA,UAAA,CAEA,WAAA,CACA,4BAAA,CACA,mBAAA,CAGF,sBACE,UAnBM,CAoBN,WAAA,CAEA,SAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CAGF,sBACE,SAAA,CACA,WAAA,CACA,sDCzBoB,CD4BtB,sBEME,eAAA,CACA,aAAA,CACA,8BC5Ca,CD6Cb,gBAAA,CACA,qBAAA,CFRA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,cAAA,CACA,QAAA,CACA,eAAA,CACA,6BI4Fe,CJ1Ff,sDCtCoB,CDwCpB,iBAAA,CACA,0BAAA,CAEA,kBAAA,CAEA,sBACE,UAAA,CACA,QAAA,CACA,SAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var container = `eFNEoutr7jDBGHsT5qBD`;
var _1 = `Rnn9UP2udb_qbAAGum77`;
export { _1 as "ghost-handler" };
var _2 = `KRmAxlyq0eap546MflQc`;
export { _2 as "ghost-marker" };
var _3 = `DsGRjUAd8bzdlN7mUSwP`;
export { _3 as "ghost-label" };
var _4 = `nb8wSjl4Eb2tSQ_fIS9q`;
export { _4 as "ghost-label-text" };
export default ___CSS_LOADER_EXPORT___;
