// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Uhw1lpaIgFkxuemCymqb{position:relative}.MX3uBcuwBprs5ZZx8k24{--bg-color: var(--figma-color-bg);position:absolute;top:0;height:calc(100% - 1px);overflow:hidden;background-color:var(--figma-color-bg);border-radius:4px}.MX3uBcuwBprs5ZZx8k24::after{position:absolute;top:0;left:0;width:100%;height:100%;background-color:var(--bg-color);content:""}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/property-group/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAGF,sBACE,iCAAA,CACA,iBAAA,CACA,KAAA,CACA,uBAAA,CACA,eAAA,CACA,sCCVa,CDWb,iBAAA,CAEA,6BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,gCAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
export var container = `Uhw1lpaIgFkxuemCymqb`;
export var background = `MX3uBcuwBprs5ZZx8k24`;
export default ___CSS_LOADER_EXPORT___;
