// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PLL8j3dAIIw1JdrYEgY3{box-sizing:border-box;width:30px;height:30px;padding:0;border-radius:2px;cursor:default}.PLL8j3dAIIw1JdrYEgY3:hover{background:rgba(0,0,0,0);border:2px solid var(--figma-color-bg-hover)}.PLL8j3dAIIw1JdrYEgY3:focus{border:2px solid var(--figma-color-bg-brand)}.VUuzEFdg9AyAPi3Rc6bC{box-sizing:border-box;width:24px;height:24px;padding:0;background:var(--aninix-color-foreground-black-1);border-radius:2px;cursor:default}.VUuzEFdg9AyAPi3Rc6bC:hover{background:var(--aninix-color-foreground-black-1);border:2px solid #fff;outline:1px solid var(--aninix-color-foreground-black-1)}.VUuzEFdg9AyAPi3Rc6bC:focus{border:2px solid #fff;outline:2px solid var(--figma-color-bg-brand)}.VUuzEFdg9AyAPi3Rc6bC:disabled{background:var(--aninix-color-foreground-black-1)}.EYHTuR7er2wwJT63yx5f{background:rgba(0,0,0,.8)}.pCQu1LSUkZKjuz4yJpMw{background:var(--aninix-color-foreground-black-1)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/buttons/OptionButton/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/colors.scss"],"names":[],"mappings":"AAEA,sBACE,qBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,iBAAA,CACA,cAAA,CAEA,4BACE,wBAAA,CACA,4CAAA,CAGF,4BACE,4CAAA,CAIJ,sBACE,qBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,iDCrBoB,CDsBpB,iBAAA,CACA,cAAA,CAEA,4BACE,iDC1BkB,CD2BlB,qBAAA,CACA,wDAAA,CAGF,4BACE,qBAAA,CACA,6CAAA,CAGF,+BACE,iDCrCkB,CDyCtB,sBACE,yBELmB,CFQrB,sBACE,iDC9CoB","sourceRoot":""}]);
// Exports
var _1 = `PLL8j3dAIIw1JdrYEgY3`;
export { _1 as "root-off" };
var _2 = `VUuzEFdg9AyAPi3Rc6bC`;
export { _2 as "root-on" };
var _3 = `EYHTuR7er2wwJT63yx5f`;
export { _3 as "disabled-off" };
var _4 = `pCQu1LSUkZKjuz4yJpMw`;
export { _4 as "disabled-on" };
export default ___CSS_LOADER_EXPORT___;
