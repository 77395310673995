// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes EGqvhNuEQ4tW2ecz0VM1{0%{opacity:0%;transform:scale(0.9) translateY(0px)}100%{opacity:100%;transform:scale(1) translateY(12px)}}.CJK1lHuelted9G7PvVEU{animation-name:EGqvhNuEQ4tW2ecz0VM1;animation-duration:125ms;animation-timing-function:cubic-bezier(0, 0, 0.15, 1);animation-fill-mode:both}`, "",{"version":3,"sources":["webpack://./packages/core/src/modules/inspector/toolbar/components/remix.scss"],"names":[],"mappings":"AAAA,gCACE,GACE,UAAA,CACA,oCAAA,CAGF,KACE,YAAA,CACA,mCAAA,CAAA,CAIJ,sBACE,mCAAA,CACA,wBAAA,CACA,qDAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
export var tooltip = `CJK1lHuelted9G7PvVEU`;
export var appear = `EGqvhNuEQ4tW2ecz0VM1`;
export default ___CSS_LOADER_EXPORT___;
