import { Project } from '@aninix-inc/model'
import {
  ApiDeleteParams,
  ApiGetResponse,
  ApiPostRequestBody,
  httpClient,
} from '@aninix/api'
import { useProject } from '@aninix/core/stores'
import { toast } from 'apps/web-app/src/modules/toasts'
import { AxiosResponse } from 'axios'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { createEmptyProject } from '../overrides-export/utils/create-empty-project'
import { getDiffSnapshot } from '../overrides-export/utils/make-diff-snapshot'
import { makeOverrideProject } from './make-override-project'

export interface EntityType {
  id: string
  name: string
  value: any
}

export interface ColorType extends EntityType {
  type: 'color'
  value: string
}

export interface TextType extends EntityType {
  type: 'text'
  value: string
}

export interface MediaType extends EntityType {
  type: 'media'
  value: {
    opacity: number
    src: string
    type: string
    thumbnail: string
  }
}

export type Override =
  ApiGetResponse<'/v2/projects/{projectId}/overrides'>[number]

export type OverrideWithProject =
  ApiGetResponse<'/v2/projects/{projectId}/overrides'>[number] & {
    project: Project
    retrieveProjectFromInitialData: () => Project
  }

interface IOVerridesContext {
  data: OverrideWithProject[]
  createOverride: (fromOverrideId?: string) => void
  deleteOverride: (overrideId: string) => void
  selectedOverride: string
  setSelectedOverride: (value: string) => void
}

const OverridesContext = React.createContext<IOVerridesContext | null>(null)

interface IProps {}
export const OverridesProvider: React.FCC<IProps> = ({ children }) => {
  const { projectId = 'no-project-id' } = useParams()
  const [data, setData] = React.useState<OverrideWithProject[]>([])

  const [selectedOverride, setSelectedOverride] =
    React.useState<string>('original')

  const createOverride = (fromOverrideId?: string) => {
    const foundOverride = fromOverrideId
      ? data.find((o) => o.id === fromOverrideId)
      : undefined

    if (fromOverrideId != null && foundOverride == null)
      throw Error(`Couldn't find existing override with this id`)

    const referenceProject =
      foundOverride?.project ?? createEmptyProject(project.id)

    const referenceSnapshot = getDiffSnapshot({
      originalProject: project,
      overridenProject: referenceProject,
    })

    httpClient
      .post<
        ApiPostRequestBody<'/v2/projects/{projectId}/overrides'>,
        AxiosResponse<Override>
      >(`/v2/projects/${projectId}/overrides`, {
        snapshot: referenceSnapshot,
        name: `Variation ${data.length}`,
      })
      .then((response) => {
        setData((oldData) => [
          ...oldData,
          mapOverrideToOverrideWithProjects(response.data, project),
        ])
        setTimeout(() => setSelectedOverride(response.data.id), 200)
      })
      .catch(() => {
        toast(`Couldn't create new variation`, { variant: 'error' })
      })
  }

  const deleteOverride = (overrideId?: string) => {
    httpClient
      .delete<
        ApiDeleteParams<'/v2/projects/{projectId}/overrides/{overrideId}'>
      >(`/v2/projects/${projectId}/overrides/${overrideId}`)
      .then((response) => {
        setData((oldData) => [...oldData.filter((d) => d.id !== overrideId)])
        if (selectedOverride === overrideId) {
          setSelectedOverride('original')
        }
      })
      .catch(() => {
        toast(`Couldn't delete variation`, { variant: 'error' })
      })
  }

  const project = useProject()

  const originalOverride: Override = {
    id: 'original',
    projectId,
    snapshot: project.getSnapshot(),
    createdAt: new Date().toISOString(),
    name: 'Original',
  }

  React.useEffect(() => {
    httpClient
      .get<Override[]>(`/v2/projects/${projectId}/overrides`)
      .then((response) => {
        if (response.status !== 200) return

        const overridesData = response.data.filter((v) => v != null)
        const overrides = [originalOverride, ...overridesData].map((o) =>
          mapOverrideToOverrideWithProjects(o, project)
        )
        setData(overrides)
      })
      .catch(() => {
        toast(`Couldn't load variations`, { variant: 'error' })
      })
  }, [])

  return (
    <OverridesContext.Provider
      value={{
        data,
        createOverride,
        deleteOverride,
        selectedOverride,
        setSelectedOverride,
      }}
    >
      {children}
    </OverridesContext.Provider>
  )
}

export const useOverrides = () => {
  const context = React.useContext(OverridesContext)

  if (!context) {
    throw new Error('useOverrides must be used within a OverridesProvider')
  }

  return context
}

const mapOverrideToOverrideWithProjects = (
  override: Override,
  project: Project
) => {
  const initialProject = createEmptyProject(project.id).applySnapshot(
    project.getSnapshot()
  )

  return {
    ...override,
    project: makeOverrideProject({ override, project }),
    retrieveProjectFromInitialData: () =>
      makeOverrideProject({ override, project: initialProject }),
  }
}
