// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D6nPeSq4jvmznSC0AjPp{width:216px;max-height:297px;overflow-y:auto}.OzyIzLGJvtUrAXT5LcRb{width:100%;padding:8px;color:var(--figma-color-text);background:var(--figma-color-bg)}.yY372tcy0f9fs5G5ZQ3V{margin-left:8px}.nPiSqDnJuSoZk5stx9VF{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;display:flex;flex-direction:column;align-items:center;justify-content:center;height:48px;color:var(--figma-color-text-disabled);text-align:center}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/graph-editor/components/user-graphs/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,gBAAA,CACA,eAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,6BC6He,CD5Hf,gCCVa,CDaf,sBACE,eAAA,CAGF,sBERE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CFMA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,sCCyHuB,CDxHvB,iBAAA","sourceRoot":""}]);
// Exports
export var card = `D6nPeSq4jvmznSC0AjPp`;
var _1 = `OzyIzLGJvtUrAXT5LcRb`;
export { _1 as "presets-wrapper" };
var _2 = `yY372tcy0f9fs5G5ZQ3V`;
export { _2 as "margin-left" };
export var empty = `nPiSqDnJuSoZk5stx9VF`;
export default ___CSS_LOADER_EXPORT___;
