import {
  ChildrenRelationsAspect,
  ClipContentComponent,
  EffectsRelationsAspect,
  EffectTypeComponent,
  EntryComponent,
  FillsRelationsAspect,
  NameComponent,
  NodeType,
  NodeTypeComponent,
  PaintTypeComponent,
  ParentRelationAspect,
  StrokesRelationsAspect,
  VectorPathsComponent,
} from '@aninix-inc/model'
import * as R from 'ramda'
import { PrototypeNode } from './prototype-node'

export namespace Pair {
  export type Type = {
    left?: PrototypeNode.Type
    right?: PrototypeNode.Type

    forEachGrouped: (
      callback: (pair: Type[], parent?: PrototypeNode.Type) => void
    ) => void

    /**
     * Return true when both leafs of the pair can be transitioned via `smart animate`
     */
    canBeTransitioned: () => boolean
  }

  export class Base implements Type {
    constructor(
      public readonly left?: PrototypeNode.Type,
      public readonly right?: PrototypeNode.Type
    ) {}

    forEachGrouped: Type['forEachGrouped'] = (callback) => {
      const stack: Type[] = [this]
      const tempStack: Type[] = []
      let parent: PrototypeNode.Type | undefined
      callback([this], parent)

      while (stack.length > 0) {
        if (tempStack.length !== 0) {
          callback(tempStack, parent)
          tempStack.length = 0
          parent = undefined
          continue
        }

        const pair = stack.pop()!

        if (
          pair.left?.entity.hasAspect(ChildrenRelationsAspect) &&
          pair.right?.entity.hasAspect(ChildrenRelationsAspect)
        ) {
          const leftChildren = [
            ...pair.left.entity
              .getAspectOrThrow(ChildrenRelationsAspect)
              .getChildrenList(),
          ]
          const rightChildren = [
            ...pair.right.entity
              .getAspectOrThrow(ChildrenRelationsAspect)
              .getChildrenList(),
          ]

          for (
            let rightChildIdx = 0;
            rightChildIdx < rightChildren.length;
            rightChildIdx += 1
          ) {
            const rightChild = rightChildren[rightChildIdx]

            const leftChildIdx = leftChildren.findIndex(
              (child) =>
                rightChild.getComponentOrThrow(NameComponent).value ===
                child.getComponentOrThrow(NameComponent).value
            )

            const [leftChild] =
              leftChildIdx !== -1 ? leftChildren.splice(leftChildIdx, 1) : []
            if (leftChild != null) {
              tempStack.push(
                new Base(
                  new PrototypeNode.Base(leftChild),
                  new PrototypeNode.Base(rightChild)
                )
              )
              parent = pair.left
              stack.push(
                new Base(
                  new PrototypeNode.Base(leftChild),
                  new PrototypeNode.Base(rightChild)
                )
              )
              continue
            }

            tempStack.push(
              new Base(undefined, new PrototypeNode.Base(rightChild))
            )
            parent = pair.left
          }

          // @NOTE: push all pairs when left leaf not found
          for (const leftChild of leftChildren) {
            tempStack.push(
              new Base(new PrototypeNode.Base(leftChild), undefined)
            )
            parent = pair.left
          }
        }
      }

      if (tempStack.length !== 0) {
        callback(tempStack, parent)
        tempStack.length = 0
        parent = undefined
      }
    }

    canBeTransitioned: Type['canBeTransitioned'] = () => {
      if (
        this.left?.entity.hasComponent(EntryComponent) &&
        this.right?.entity.hasComponent(EntryComponent)
      ) {
        return true
      }

      // @NOTE: check clip content
      if (
        this.left?.entity.hasComponent(ClipContentComponent) &&
        this.right?.entity.hasComponent(ClipContentComponent) &&
        this.left.entity.getComponentOrThrow(ClipContentComponent).value !==
          this.right.entity.getComponentOrThrow(ClipContentComponent).value
      ) {
        // @TODO: enable. Can be tested on the aninix-to-figma project
        // return false
      }

      // @NOTE: check indicies
      const leftIndex = this.left?.entity
        .getAspect(ParentRelationAspect)
        ?.getParentEntity()
        ?.getAspect(ChildrenRelationsAspect)
        ?.getIndexOf(this.left.entity)
      const rightIndex = this.right?.entity
        .getAspect(ParentRelationAspect)
        ?.getParentEntity()
        ?.getAspect(ChildrenRelationsAspect)
        ?.getIndexOf(this.right.entity)

      if (leftIndex != null && rightIndex != null) {
        const leftChildrenNames = [
          ...(this.left?.entity
            .getAspect(ParentRelationAspect)
            ?.getParentEntity()
            ?.getAspect(ChildrenRelationsAspect)
            ?.getChildrenList() ?? []),
        ].map((child) => child.getComponentOrThrow(NameComponent).value)
        const rightChildrenNames = [
          ...(this.right?.entity
            .getAspect(ParentRelationAspect)
            ?.getParentEntity()
            ?.getAspect(ChildrenRelationsAspect)
            ?.getChildrenList() ?? []),
        ].map((child) => child.getComponentOrThrow(NameComponent).value)

        const indiciesAdded: number[] = []
        const indiciesRemoved: number[] = []
        const indiciesBlended = new Map<number, number>()
        const affectedIndicies = new Set<number>()

        for (
          let rightChildIdx = rightChildrenNames.length - 1;
          rightChildIdx >= 0;
          rightChildIdx -= 1
        ) {
          const rightChildName = rightChildrenNames[rightChildIdx]
          const leftChildIdx = R.lastIndexOf(rightChildName, leftChildrenNames)

          if (leftChildIdx !== -1 && !affectedIndicies.has(leftChildIdx)) {
            affectedIndicies.add(leftChildIdx)
            indiciesBlended.set(leftChildIdx, leftChildIdx)
            continue
          }

          indiciesAdded.push(rightChildIdx)
        }

        for (
          let leftChildIdx = 0;
          leftChildIdx < leftChildrenNames.length;
          leftChildIdx += 1
        ) {
          if (affectedIndicies.has(leftChildIdx)) {
            continue
          }

          indiciesRemoved.push(leftChildIdx)
        }

        const modifiedIndiciesBlended = new Map<number, number>()
        for (const [leftChildIdx, rightChildIdx] of indiciesBlended.entries()) {
          modifiedIndiciesBlended.set(
            leftChildIdx,
            rightChildIdx +
              R.count((index) => index <= rightChildIdx, indiciesAdded) -
              R.count((index) => index <= rightChildIdx, indiciesRemoved)
          )
        }

        const leftIndexToCompare = modifiedIndiciesBlended.get(leftIndex) ?? 0
        if (leftIndexToCompare < rightIndex) {
          return false
        }
      }

      // @NOTE: type
      const leftType =
        this.left?.entity.getComponentOrThrow(NodeTypeComponent).value
      const rightType =
        this.right?.entity.getComponentOrThrow(NodeTypeComponent).value
      if (leftType !== rightType) {
        return false
      }

      // @NOTE: vector
      if (
        (leftType === NodeType.Vector ||
          leftType === NodeType.BooleanOperation) &&
        R.not(
          R.equals(
            this.left?.entity.getComponentOrThrow(VectorPathsComponent).value,
            this.right?.entity.getComponentOrThrow(VectorPathsComponent).value
          )
        )
      ) {
        // @TODO: enable different vectors animation
        return false
      }

      // @NOTE: fills related
      const leftFillsAspect = this.left?.entity.getAspect(FillsRelationsAspect)
      const rightFillsAspect =
        this.right?.entity.getAspect(FillsRelationsAspect)
      if (leftFillsAspect != null && rightFillsAspect == null) {
        return false
      }
      if (leftFillsAspect == null && rightFillsAspect != null) {
        return false
      }
      if (leftFillsAspect != null && rightFillsAspect != null) {
        const leftFills = leftFillsAspect.getChildrenList()
        const rightFills = rightFillsAspect.getChildrenList()
        if (leftFills.length !== rightFills.length) {
          return false
        }
        for (let i = 0; i < leftFills.length; i += 1) {
          const leftFill = leftFills[i]
          const rightFill = rightFills[i]
          if (
            leftFill.getComponentOrThrow(PaintTypeComponent).value !==
            rightFill.getComponentOrThrow(PaintTypeComponent).value
          ) {
            return false
          }
        }
      }

      // @NOTE: strokes related
      const leftStrokesAspect = this.left?.entity.getAspect(
        StrokesRelationsAspect
      )
      const rightStrokesAspect = this.right?.entity.getAspect(
        StrokesRelationsAspect
      )
      if (leftStrokesAspect != null && rightStrokesAspect == null) {
        return false
      }
      if (leftStrokesAspect == null && rightStrokesAspect != null) {
        return false
      }
      if (leftStrokesAspect != null && rightStrokesAspect != null) {
        const leftStrokes = leftStrokesAspect.getChildrenList()
        const rightStrokes = rightStrokesAspect.getChildrenList()
        if (leftStrokes.length !== rightStrokes.length) {
          return false
        }
        for (let i = 0; i < leftStrokes.length; i += 1) {
          const leftStroke = leftStrokes[i]
          const rightStroke = rightStrokes[i]
          if (
            leftStroke.getComponentOrThrow(PaintTypeComponent).value !==
            rightStroke.getComponentOrThrow(PaintTypeComponent).value
          ) {
            return false
          }
        }
      }

      // @NOTE: effects related
      const leftEffectsAspect = this.left?.entity.getAspect(
        EffectsRelationsAspect
      )
      const rightEffectsAspect = this.right?.entity.getAspect(
        EffectsRelationsAspect
      )
      if (leftEffectsAspect != null && rightEffectsAspect == null) {
        return false
      }
      if (leftEffectsAspect == null && rightEffectsAspect != null) {
        return false
      }
      if (leftEffectsAspect != null && rightEffectsAspect != null) {
        const leftEffects = leftEffectsAspect.getChildrenList()
        const rightEffects = rightEffectsAspect.getChildrenList()
        if (leftEffects.length !== rightEffects.length) {
          return false
        }
        for (let i = 0; i < leftEffects.length; i += 1) {
          const leftEffect = leftEffects[i]
          const rightEffect = rightEffects[i]
          if (
            leftEffect.getComponentOrThrow(EffectTypeComponent).value !==
            rightEffect.getComponentOrThrow(EffectTypeComponent).value
          ) {
            return false
          }
        }
      }

      return true
    }
  }
}
