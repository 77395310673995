// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fCN_g_jrL7nwioAG_6wB{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100vw;max-width:480px;height:100vh;margin:0 auto}.lejz8xtF7DnMBhhky4OG{font-weight:600;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;color:rgba(0,0,0,.8);text-align:center}.ifj56OEylHVo_WjhnfNV{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;color:rgba(0,0,0,.8);text-align:center}.UN2xH7IxIVCBdY_3ybQ0{display:flex;flex-direction:column;align-items:center;justify-content:center;margin:16px 8px 24px 8px}.Wqp3hl1UmX8k2ZUBj5bj{display:flex;flex-direction:row;gap:12px;justify-content:center}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/error-boundary/router.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/colors.scss"],"names":[],"mappings":"AAMA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,aAAA,CAGF,sBCgFE,eAAA,CACA,cAAA,CACA,8BCnGa,CDoGb,gBAAA,CACA,qBAAA,CDlFA,QAAA,CACA,oBGImB,CHHnB,iBAAA,CAGF,sBCbE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CDWA,QAAA,CACA,oBGHmB,CHInB,iBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,wBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
export var color = `var(--figma-color-bg-danger)`;
export var container = `fCN_g_jrL7nwioAG_6wB`;
export var bold = `lejz8xtF7DnMBhhky4OG`;
var _1 = `ifj56OEylHVo_WjhnfNV`;
export { _1 as "main-message" };
export var description = `UN2xH7IxIVCBdY_3ybQ0`;
export var buttons = `Wqp3hl1UmX8k2ZUBj5bj`;
export default ___CSS_LOADER_EXPORT___;
