// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RBg7xzEBF837_YbI_DR8{width:290px}.si4rqB0e8vTpjQjB3eqm{color:var(--figma-color-text);background:var(--figma-color-bg)}.LxkU60hxryvlpzjJEhzU{display:flex;align-items:center;justify-content:flex-end;padding:8px}.uphZRTzkn4LPAH5ejWfe{margin-right:8px}.gVA7x81PFSh8FqJiLDqH{display:flex;align-items:center;padding:8px}.gVA7x81PFSh8FqJiLDqH div:last-child{width:100%}.HfTC_lPD0r3Jd5QAMNbQ{margin-right:8px}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/graph-editor/components/save-user-graph/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CAGF,sBACE,6BCiIe,CDhIf,gCCNa,CDSf,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,WAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CAGE,qCACE,UAAA,CAKN,sBACE,gBAAA","sourceRoot":""}]);
// Exports
export var card = `RBg7xzEBF837_YbI_DR8`;
export var content = `si4rqB0e8vTpjQjB3eqm`;
export var buttons = `LxkU60hxryvlpzjJEhzU`;
var _1 = `uphZRTzkn4LPAH5ejWfe`;
export { _1 as "left-button" };
export var input = `gVA7x81PFSh8FqJiLDqH`;
export var icon = `HfTC_lPD0r3Jd5QAMNbQ`;
export default ___CSS_LOADER_EXPORT___;
