// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m6wtQJrrZElN0sG6AWey{position:relative;flex:1;min-width:48px;max-width:fit-content;height:32px;padding:8px 24px 8px 12px;color:#fff;background-color:#000;border-radius:6px;cursor:default;opacity:.4}.m6wtQJrrZElN0sG6AWey:hover{opacity:.7}.m6wtQJrrZElN0sG6AWey:hover .Rf9uFbLLqlxSrCe2C3o8{opacity:1}.PQFPtyzSVHeT_FplUlQl{opacity:1}.PQFPtyzSVHeT_FplUlQl:hover{opacity:1}.b48mTyMdGn1bLQG17K6f{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;padding:0;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.Rf9uFbLLqlxSrCe2C3o8{--figma-color-text: rgba(255, 255, 255, 0.6);position:absolute;top:50%;right:8px;z-index:1;flex-shrink:0;width:16px;height:16px;margin:0;background:none;border:none;transform:translateY(-50%);opacity:0}.Rf9uFbLLqlxSrCe2C3o8:hover{--figma-color-text: #ffffff}.Rf9uFbLLqlxSrCe2C3o8::before{position:absolute;top:0;right:0;z-index:-1;width:32px;height:100%;background:linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 67%);content:"";pointer-events:none}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/project-tab/index.scss","webpack://./packages/app-design-system/src/theme/colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,MAAA,CACA,cAAA,CACA,qBAAA,CACA,WAAA,CACA,yBAAA,CACA,UCkBiB,CDjBjB,qBCmCiB,CDlCjB,iBAAA,CACA,cAAA,CACA,UAAA,CAEA,4BACE,UAAA,CAEA,kDACE,SAAA,CAIJ,sBACE,SAAA,CAEA,4BACE,SAAA,CAKN,sBErBE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CFmBA,QAAA,CACA,SAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,4CAAA,CACA,iBAAA,CACA,OAAA,CACA,SAAA,CACA,SAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,eAAA,CACA,WAAA,CACA,0BAAA,CACA,SAAA,CAEA,4BACE,2BAAA,CAGF,8BACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,mEAAA,CAKA,UAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
export var container = `m6wtQJrrZElN0sG6AWey`;
export var close = `Rf9uFbLLqlxSrCe2C3o8`;
var _1 = `PQFPtyzSVHeT_FplUlQl`;
export { _1 as "container--selected" };
export var title = `b48mTyMdGn1bLQG17K6f`;
export default ___CSS_LOADER_EXPORT___;
