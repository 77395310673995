// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w0dMEcwfcV76zm9Beocg{display:flex;align-items:center;width:154px;height:32px;border:1px solid rgba(0,0,0,0)}.w0dMEcwfcV76zm9Beocg:hover{border-color:var(--aninix-color-foreground-black-1);border-radius:2px}.w0dMEcwfcV76zm9Beocg:hover input{border-right:1px solid var(--aninix-color-foreground-black-1)}.w0dMEcwfcV76zm9Beocg:hover input:last-child{border-right:none}.crAcCZWj2RaXdD8ixUYG{border-color:var(--figma-color-bg-brand) !important;box-shadow:0 0 0 1px var(--figma-color-bg-brand)}.P70eLTQZFFF0qtizAZFn{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;width:35px;height:30px;padding-left:7px;color:var(--figma-color-text);background:rgba(0,0,0,0);border:none}.P70eLTQZFFF0qtizAZFn:hover{outline:none}.P70eLTQZFFF0qtizAZFn:focus{outline:none}.K8Yozs3l45VBU6zZ3KJO{width:46px}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/input-rgba/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,WAAA,CACA,8BAAA,CAEA,4BACE,mDCNkB,CDOlB,iBAAA,CAEA,kCACE,6DAAA,CAEA,6CACE,iBAAA,CAKN,sBACE,mDAAA,CACA,gDAAA,CAIJ,sBEjBE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CFeA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,6BIuGe,CJtGf,wBAAA,CACA,WAAA,CAEA,4BACE,YAAA,CAGF,4BACE,YAAA,CAIJ,sBACE,UAAA","sourceRoot":""}]);
// Exports
var _1 = `w0dMEcwfcV76zm9Beocg`;
export { _1 as "inputs-wrapper" };
var _2 = `crAcCZWj2RaXdD8ixUYG`;
export { _2 as "inputs-wrapper--focused" };
export var input = `P70eLTQZFFF0qtizAZFn`;
export var percent = `K8Yozs3l45VBU6zZ3KJO`;
export default ___CSS_LOADER_EXPORT___;
