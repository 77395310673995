// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NadfWqIiOqynz3MyxuIw{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:flex-start;padding:0 8px;border-bottom:1px solid var(--aninix-color-foreground-black-05)}.zwyu4xCJB3IhO5TPETQp{font-weight:500;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;padding:12px 8px;color:var(--figma-color-text-disabled);background:none;border:none;outline:none;cursor:default}.zwyu4xCJB3IhO5TPETQp:hover{color:var(--figma-color-text)}.Rd2vErs2uLyF0oF3JJ0b{font-weight:600;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;color:var(--figma-color-text)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/properties-panel/view-tabs.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,0BAAA,CACA,aAAA,CACA,+DAAA,CAGF,sBCsCE,eAAA,CACA,cAAA,CACA,8BCpDa,CDqDb,gBAAA,CACA,qBAAA,CDxCA,gBAAA,CACA,sCGoIuB,CHnIvB,eAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CAEA,4BACE,6BGkHa,CH/Gf,sBCwEA,eAAA,CACA,cAAA,CACA,8BCnGa,CDoGb,gBAAA,CACA,qBAAA,CD1EE,6BG6Ga","sourceRoot":""}]);
// Exports
export var container = `NadfWqIiOqynz3MyxuIw`;
export var tab = `zwyu4xCJB3IhO5TPETQp`;
var _1 = `Rd2vErs2uLyF0oF3JJ0b`;
export { _1 as "tab--highlighted" };
export default ___CSS_LOADER_EXPORT___;
