// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZkxsIE4UVkeKwGuCzYYC{width:100%}.ZkxsIE4UVkeKwGuCzYYC .xzsNVcmrrXCf1BjD603D{-ms-overflow-style:none !important;scrollbar-width:none !important}.ZkxsIE4UVkeKwGuCzYYC .xzsNVcmrrXCf1BjD603D::-webkit-scrollbar{display:none !important}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/virtualized-list/styles.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,4CACE,kCAAA,CACA,+BAAA,CAGA,+DACE,uBAAA","sourceRoot":""}]);
// Exports
export var list = `ZkxsIE4UVkeKwGuCzYYC`;
export var grid = `xzsNVcmrrXCf1BjD603D`;
export default ___CSS_LOADER_EXPORT___;
