// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LRRTlv3e5MVXfhxUxwhs{background-color:var(--figma-color-bg)}._2seQPpyzuMk6QLJB4AK{overflow:hidden}.o3gh5rttkji73Bawe4lG{position:relative}._ULQtZnentViiQo8xXnv{border-bottom:1px solid var(--aninix-color-foreground-black-1)}.EDQtmGSgVqfMhRF8vqHg{position:relative;z-index:4;display:flex;flex-direction:column;background-color:var(--figma-color-bg)}.NpK2bBQTihVCVk88SgkG{position:relative;z-index:1;background-color:var(--aninix-color-foreground-black-1)}.gCrqZbkDd8RDA6nGKXW0{position:relative;z-index:4;background:var(--aninix-color-foreground-black-05)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/tracks/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss"],"names":[],"mappings":"AAOA,sBACE,sCCNa,CDSf,sBACE,eAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,8DAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,YAAA,CACA,qBAAA,CACA,sCC1Ba,CD6Bf,sBACE,iBAAA,CACA,SAAA,CACA,uDE9BoB,CFiCtB,sBACE,iBAAA,CACA,SAAA,CACA,kDErCqB","sourceRoot":""}]);
// Exports
export var properties_panel_width = `240px`;
export var container = `LRRTlv3e5MVXfhxUxwhs`;
var _1 = `_2seQPpyzuMk6QLJB4AK`;
export { _1 as "tracks-container" };
var _2 = `o3gh5rttkji73Bawe4lG`;
export { _2 as "layers-container" };
export var layer = `_ULQtZnentViiQo8xXnv`;
var _3 = `EDQtmGSgVqfMhRF8vqHg`;
export { _3 as "header-container" };
var _4 = `NpK2bBQTihVCVk88SgkG`;
export { _4 as "nodes-list" };
var _5 = `gCrqZbkDd8RDA6nGKXW0`;
export { _5 as "visible-range-container" };
export default ___CSS_LOADER_EXPORT___;
