// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NpoQYbYrl_ZUb9gwGu3P::after{@apply absolute left-1/2 top-0 h-full w-[1px] -translate-x-1/2;background-color:var(--mask-color);content:""}.z20kGKCsTEQRLEM8auJw{@apply flex flex-col items-center justify-center;padding:0 2px}.i02lcJ9HVtCsHWTec7Rt{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/info/property/index.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAKI,6BACE,8DAAA,CACA,kCAAA,CACA,UAAA,CAKN,sBACE,gDAAA,CACA,aAAA,CAGF,sBCPE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
export var mask__between = `NpoQYbYrl_ZUb9gwGu3P`;
export var component = `z20kGKCsTEQRLEM8auJw`;
export var name = `i02lcJ9HVtCsHWTec7Rt`;
export default ___CSS_LOADER_EXPORT___;
