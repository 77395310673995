// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uVODt8wGyO9bMZLpwWmi{display:flex;align-items:center;height:32px}.uVODt8wGyO9bMZLpwWmi:hover{background:var(--aninix-color-foreground-black-1)}.BH6zCCLXTkHif4rohjLK{display:flex;align-items:center;width:100%;height:100%;padding:0;background:none;border:none;outline:none}.vKIQrGchzi6O2z3AMo0Q{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin-left:8px}.KYIf4m8fe5dnpllljFZw{margin-left:8px;transform:translateY(2px)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/graph-editor/components/user-graphs/components/Folder/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CAEA,4BACE,iDCJkB,CDQtB,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAEA,SAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CAGF,sBEbE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CFWA,eAAA,CAGF,sBACE,eAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
export var folder = `uVODt8wGyO9bMZLpwWmi`;
var _1 = `BH6zCCLXTkHif4rohjLK`;
export { _1 as "folder-wrapper" };
var _2 = `vKIQrGchzi6O2z3AMo0Q`;
export { _2 as "folder-name" };
export var icon = `KYIf4m8fe5dnpllljFZw`;
export default ___CSS_LOADER_EXPORT___;
