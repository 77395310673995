// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qjO27JRqP70aEIB3mM9I{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:space-between;padding:8px 8px 8px 16px}._kzDPyDkEiDsfucOSW3s{font-weight:600;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/popup-header/index.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,6BAAA,CACA,wBAAA,CAGF,sBCsFE,eAAA,CACA,cAAA,CACA,8BCnGa,CDoGb,gBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
export var header = `qjO27JRqP70aEIB3mM9I`;
export var content = `_kzDPyDkEiDsfucOSW3s`;
export default ___CSS_LOADER_EXPORT___;
