import {
  Point2D,
  Point2dKeyframe,
  SkewComponent,
  getValuePoint2d,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { Point2dValue } from '../../values/point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export const Skew: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, snapshots, time } = useNodePropertiesPanel()

  const components = nodes.map((l) => l.getComponentOrThrow(SkewComponent))
  const keyframeType = getKeyframesType(components, time)
  const skews = snapshots.map((s) => s.skew)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <SkewEditable time={time} components={components} />
      ) : (
        <SkewDisplay
          time={time}
          components={components}
          skews={skews}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

Skew.displayName = 'Skew'

const SkewEditable: React.FC<{ components: SkewComponent[]; time: number }> = ({
  components,
  time,
}) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <Point2dValue
            components={components}
            time={time}
            iconX={<icons.propertiesPanel.SkewX />}
            iconY={<icons.propertiesPanel.SkewY />}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={Point2dKeyframe}
          valueGetter={getValuePoint2d}
        />
      }
    />
  )
}

SkewEditable.displayName = 'SkewEditable'

interface SkewDisplayProps {
  components: SkewComponent[]
  time: number
  skews: Point2D[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (prev: SkewDisplayProps, next: SkewDisplayProps) => {
  if (prev.skews.length !== next.skews.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.skews.length; i++) {
    if (
      prev.skews[i].x !== next.skews[i].x ||
      prev.skews[i].y !== next.skews[i].y
    )
      return false
  }
  return true
}

const SkewDisplay: React.FC<SkewDisplayProps> = React.memo(
  ({ components, time }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <Point2dValue
              components={components}
              time={time}
              iconX={<icons.propertiesPanel.SkewX />}
              iconY={<icons.propertiesPanel.SkewY />}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={Point2dKeyframe}
            valueGetter={getValuePoint2d}
          />
        }
      />
    )
  },
  propsAreEqual
)

SkewDisplay.displayName = 'SkewDisplay'
