// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T3Y5Gh0FAa5Lnbgov2do{--color: white}.ovhVqCbc_aQs46Xgsshg{fill:var(--color)}.AJGnFNLgve_ZyS18qm6t{stroke:var(--color)}`, "",{"version":3,"sources":["webpack://./packages/editor/src/components/preset-customizer/icons/index.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CAEA,sBACE,iBAAA,CAGF,sBACE,mBAAA","sourceRoot":""}]);
// Exports
export var icon = `T3Y5Gh0FAa5Lnbgov2do`;
export var icon__fill = `ovhVqCbc_aQs46Xgsshg`;
export var icon__stroke = `AJGnFNLgve_ZyS18qm6t`;
export default ___CSS_LOADER_EXPORT___;
