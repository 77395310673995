import { SizeComponent, SizeLockedComponent } from '@aninix-inc/model'
import { Vec2 } from '@aninix-inc/model/base/values'
import { PropertyRowV2 } from '@aninix/app-design-system'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { LockablePoint2dValue } from '../../values/lockable-point-2d'

export const Size: React.FC = () => {
  const { nodes } = useNodePropertiesPanel()

  const filteredNodes = nodes.filter(
    (n) => n.hasComponent(SizeComponent) && n.hasComponent(SizeLockedComponent)
  )

  const components = filteredNodes.map((n) =>
    n.getComponentOrThrow(SizeComponent)
  )
  const lockableComponents = filteredNodes.map((n) =>
    n.getComponentOrThrow(SizeLockedComponent)
  )
  const sizes = components.map((c) => c.value)

  return (
    <SizeMemo
      components={components}
      lockableComponents={lockableComponents}
      sizes={sizes}
    />
  )
}

Size.displayName = 'Size'

const SizeMemo: React.FC<{
  components: SizeComponent[]
  lockableComponents: SizeLockedComponent[]
  sizes: Vec2[]
}> = React.memo(
  ({ components, lockableComponents }) => {
    return (
      <PropertyRowV2
        name="Size"
        inputs={
          <LockablePoint2dValue
            components={components}
            lockableComponents={lockableComponents}
            iconX={<span>W</span>}
            iconY={<span>H</span>}
            minX={0}
            minY={0}
          />
        }
      />
    )
  },
  (prev, next) => {
    for (let i = 0; i < prev.sizes.length; i += 1) {
      if (!R.equals(prev.sizes[i], next.sizes[i])) return false
    }
    return true
  }
)

SizeMemo.displayName = 'SizeMemo'
