// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tVPURj0ELOG0WypHj4OQ{position:relative;display:flex;flex-direction:row;flex-wrap:nowrap;align-items:stretch;justify-content:flex-start;background-color:var(--figma-color-bg)}.LyUq3vN5tkUMTFHIwFxC{position:absolute;top:0;left:0;z-index:1001;width:100%;height:2px;cursor:ns-resize}.LyUq3vN5tkUMTFHIwFxC::after{position:absolute;top:50%;left:0;width:100%;height:4px;transform:translateY(-50%);content:""}.y7U6ULTrqXuxQP1C9alb::after{background-color:var(--figma-color-bg-brand)}.VKgh2Z3A7J_f4JThu5oX{flex-shrink:0;border-right:1px solid var(--aninix-color-foreground-black-05)}.zASF1XfQ1otQFrDaEOrV{position:relative;flex:1}.zASF1XfQ1otQFrDaEOrV>div{height:100%}.GIJUkWWDYfVmPAwZkg1G{position:absolute;top:0;right:2px;z-index:100;width:4px;height:30px;padding:0;background:none;border:none;outline:none}.GIJUkWWDYfVmPAwZkg1G span{display:block;width:100%;height:100%;padding:1px;background-color:gray;border-radius:100px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/index.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAGA,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,mBAAA,CACA,0BAAA,CACA,sCCRa,CDWf,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,YAAA,CACA,UAAA,CACA,UAAA,CACA,gBAAA,CAEA,6BACE,iBAAA,CACA,OAAA,CACA,MAAA,CACA,UAAA,CACA,UAAA,CACA,0BAAA,CACA,UAAA,CAIA,6BACE,4CC/Bc,CDoCpB,sBACE,aAAA,CACA,8DAAA,CAGF,sBACE,iBAAA,CACA,MAAA,CAEA,0BACE,WAAA,CAIJ,sBACE,iBAAA,CACA,KAAA,CACA,SAAA,CACA,WAAA,CACA,SAAA,CACA,WAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CAEA,2BACE,aAAA,CAEA,UAAA,CACA,WAAA,CAEA,WAAA,CACA,qBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
export var body = `tVPURj0ELOG0WypHj4OQ`;
export var resizer = `LyUq3vN5tkUMTFHIwFxC`;
var _1 = `y7U6ULTrqXuxQP1C9alb`;
export { _1 as "resizer--active" };
var _2 = `VKgh2Z3A7J_f4JThu5oX`;
export { _2 as "info-wrapper" };
var _3 = `zASF1XfQ1otQFrDaEOrV`;
export { _3 as "tracks-wrapper" };
var _4 = `GIJUkWWDYfVmPAwZkg1G`;
export { _4 as "y-scroll-handler" };
export default ___CSS_LOADER_EXPORT___;
