// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r7OncJI_ATaPbbnOKmPQ{width:100%;border-radius:2px;box-shadow:0px 2px 14px rgba(0,0,0,.15)}.I7Ir5L1xio9oZoMpBWnR{font-weight:600;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}.IkqwibITlZjUxhytnJwE{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;margin:0;color:var(--figma-color-text)}.GtGgMwvfuFyFaiJSH1C_{display:flex;justify-content:flex-end;width:100%;padding:0 8px 8px 0}.YerNi1jj4KAym9waHFEo{height:0;padding-top:"56.25%"}.WoTy_ppIWrUWEZ7tiuxD{padding:16px 16px 8px 16px}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/BasicCard/index.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,iBAAA,CACA,uCAAA,CAGF,sBCyFE,eAAA,CACA,cAAA,CACA,8BCnGa,CDoGb,gBAAA,CACA,qBAAA,CDzFF,sBCDE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CDDA,QAAA,CACA,6BGyHe,CHtHjB,sBACE,YAAA,CACA,wBAAA,CAEA,UAAA,CACA,mBAAA,CAGF,sBACE,QAAA,CACA,oBAAA,CAGF,sBACE,0BAAA","sourceRoot":""}]);
// Exports
export var card = `r7OncJI_ATaPbbnOKmPQ`;
export var title = `I7Ir5L1xio9oZoMpBWnR`;
export var description = `IkqwibITlZjUxhytnJwE`;
var _1 = `GtGgMwvfuFyFaiJSH1C_`;
export { _1 as "card-actions" };
export var media = `YerNi1jj4KAym9waHFEo`;
export var content = `WoTy_ppIWrUWEZ7tiuxD`;
export default ___CSS_LOADER_EXPORT___;
